/**
 * @generated SignedSource<<ff06a6e2360b008b2cf7b90f5ae43510>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type useENSFromAddressResolverQuery$variables = {
  addresses: ReadonlyArray<string>;
};
export type useENSFromAddressResolverQuery$data = {
  readonly getAccountsByAddresses: ReadonlyArray<{
    readonly names: ReadonlyArray<{
      readonly name: string;
      readonly type: string;
    }>;
  }>;
};
export type useENSFromAddressResolverQuery = {
  response: useENSFromAddressResolverQuery$data;
  variables: useENSFromAddressResolverQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "addresses"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "addresses",
    "variableName": "addresses"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "Name",
  "kind": "LinkedField",
  "name": "names",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useENSFromAddressResolverQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AccountType",
        "kind": "LinkedField",
        "name": "getAccountsByAddresses",
        "plural": true,
        "selections": [
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useENSFromAddressResolverQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AccountType",
        "kind": "LinkedField",
        "name": "getAccountsByAddresses",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "687c716f15b0fc50ba85cab31a00db56",
    "id": null,
    "metadata": {},
    "name": "useENSFromAddressResolverQuery",
    "operationKind": "query",
    "text": "query useENSFromAddressResolverQuery(\n  $addresses: [AddressScalar!]!\n) {\n  getAccountsByAddresses(addresses: $addresses) {\n    names {\n      name\n      type\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "666635a08a0e9690a8169692a6997669";

export default node;
