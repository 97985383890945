import { useTranslate } from "@opensea/next-translate"
import React, { Suspense, useCallback, useState } from "react"
import { useUnmount } from "react-use"
import { Modal } from "@/design-system/Modal/Modal.react"
import { useAutoLoadQueryLoader } from "@/hooks/useAutoLoadQueryLoader"
import { useGasEstimate } from "@/hooks/useGasEstimate"
import type { WrapTokenModalQuery } from "@/lib/graphql/__generated__/WrapTokenModalQuery.graphql"
import { isPolygon, isSolana } from "@/lib/helpers/chainUtils"
import {
  useConnectedAddress,
  useConnectedChain,
} from "@/providers/Wallet/selectors"
import { useWalletActions } from "@/providers/Wallet/useWalletActions"
import { useRefreshWalletBalances } from "@/providers/WalletBalanceProvider"
import type { Asset } from "../Assets/AssetProvider.react"
import {
  useAsset,
  useAssetAction,
  useExitAssetAction,
  useSetAsset,
} from "../Assets/hooks"
import { useAssetSymbols } from "../Assets/useAssetSymbols"
import { ConfirmWrapTransaction } from "./ConfirmWrapTransaction.react"
import type { WrapTransactionData } from "./WrapTransactionDetails.react"
import { WrapTransactionDetails } from "./WrapTransactionDetails.react"
import type { DirectionValueType } from "./WrapUnwrapRadioButton.react"
import { WRAP_TOKEN_QUERY } from "./WrapTokenModalQuery"

export function WrapTokenModal() {
  const t = useTranslate("assets")

  const asset = useAsset()
  const setAsset = useSetAsset()
  const action = useAssetAction()
  const exitAssetAction = useExitAssetAction()

  const chain = useConnectedChain()
  const { switchChain } = useWalletActions()

  const [step, setStep] = useState(0)
  const showDetailsStep = () => {
    setStep(0)
  }
  const showConfirmationStep = () => {
    setStep(1)
  }

  const onClose = useCallback(async () => {
    if (asset && isPolygon(asset.chain) && chain !== asset.chain) {
      await switchChain(asset.chain)
    }

    exitAssetAction()
  }, [asset, chain, exitAssetAction, switchChain])

  const title =
    step === 0
      ? t("wrapTokenModal.title.offerBalance", "Offer balance")
      : t("wrapTokenModal.title.approveTransaction", "Approve transaction")

  return (
    <Modal
      isOpen={action === "WRAP"}
      onAfterLeave={() => {
        setAsset(null)
      }}
      onClose={onClose}
    >
      <Modal.Header
        currentStep={step}
        onBack={step > 0 ? showDetailsStep : undefined}
        onClose={onClose}
        steps={2}
        title={title}
      />
      <Modal.Body className="flex flex-1 flex-col p-4">
        {asset ? (
          <Suspense>
            <WrapTokenModalContent
              asset={asset}
              currentStep={step}
              onClose={onClose}
              showConfirmationStep={showConfirmationStep}
              showDetailsStep={showDetailsStep}
            />
          </Suspense>
        ) : null}
      </Modal.Body>
    </Modal>
  )
}

const getDirection = (asset: Asset, wrappedSymbol: string) => {
  if (isSolana(asset.chain)) {
    return "Unwrap"
  } else if (isPolygon(asset.chain)) {
    return "Wrap"
  } else if (asset.symbol === wrappedSymbol) {
    return "Unwrap"
  }
  return "Wrap"
}
export function WrapTokenModalContent({
  asset,
  currentStep,
  showDetailsStep,
  showConfirmationStep,
  onClose,
}: {
  asset: Asset
  currentStep: number
  showDetailsStep: () => void
  showConfirmationStep: () => void
  onClose: () => Promise<void>
}) {
  const address = useConnectedAddress()
  const refreshWalletBalances = useRefreshWalletBalances()

  const { baseSymbol, baseChain, wrappedSymbol, wrappedChain } =
    useAssetSymbols(asset)

  useUnmount(showDetailsStep)

  const [quantity, setQuantity] = useState("")

  const [direction, setDirection] = useState<DirectionValueType>(
    getDirection(asset, wrappedSymbol),
  )

  const queryRef = useAutoLoadQueryLoader<WrapTokenModalQuery>(
    WRAP_TOKEN_QUERY,
    address
      ? {
          address,
          baseSymbol,
          baseChain,
          wrappedSymbol,
          wrappedChain,
        }
      : null,
  )

  const { estimatingGas, gasEstimate } = useGasEstimate(
    {
      action: direction,
      chain: asset.chain,
      symbol: asset.symbol,
    },
    {
      skip: isPolygon(asset.chain) && currentStep === 0,
    },
  )
  const { unwrap, wrap } = useWalletActions()

  const onSubmitTransactionDetails = (data: WrapTransactionData) => {
    setDirection(data.direction)
    setQuantity(data.quantity)
    showConfirmationStep()
  }

  const onSubmitTransaction = useCallback(
    async (data: WrapTransactionData) => {
      if (gasEstimate) {
        const actionTrigger = data.direction === "Wrap" ? wrap : unwrap
        await actionTrigger({
          quantity: data.quantity,
          chain: asset.chain,
        })

        await onClose()
        await refreshWalletBalances()
      }
    },
    [gasEstimate, wrap, unwrap, asset.chain, onClose, refreshWalletBalances],
  )

  if (!queryRef) {
    // TODO: Loading state
    return null
  }

  if (currentStep === 0) {
    return (
      <WrapTransactionDetails
        chain={asset.chain}
        estimatedGas={gasEstimate}
        estimatingGas={estimatingGas}
        initialValues={{
          quantity,
          direction,
        }}
        onSubmit={onSubmitTransactionDetails}
        queryRef={queryRef}
        symbol={asset.symbol}
      />
    )
  }

  return (
    <ConfirmWrapTransaction
      chain={asset.chain}
      direction={direction}
      estimatedGas={gasEstimate}
      estimatingGas={estimatingGas}
      onConfirm={onSubmitTransaction}
      quantity={quantity}
      queryRef={queryRef}
    />
  )
}
