/**
 * @generated SignedSource<<19368f330c22f21e778354dece90dac1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TransactionDetailsFragment$data = {
  readonly paymentAsset: {
    readonly decimals: number;
  };
  readonly wallet: {
    readonly fundsOf: {
      readonly assetImage: string | null | undefined;
      readonly balance: string;
    };
    readonly nativeFunds: {
      readonly quantity: string;
    };
  };
  readonly " $fragmentType": "TransactionDetailsFragment";
};
export type TransactionDetailsFragment$key = {
  readonly " $data"?: TransactionDetailsFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"TransactionDetailsFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "kind": "Variable",
  "name": "chain",
  "variableName": "chain"
},
v1 = [
  (v0/*: any*/),
  {
    "kind": "Variable",
    "name": "symbol",
    "variableName": "symbol"
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "address"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "chain"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "nativeSymbol"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "symbol"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "TransactionDetailsFragment",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "address",
          "variableName": "address"
        }
      ],
      "concreteType": "WalletType",
      "kind": "LinkedField",
      "name": "wallet",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": (v1/*: any*/),
          "concreteType": "WalletFundsType",
          "kind": "LinkedField",
          "name": "fundsOf",
          "plural": false,
          "selections": [
            {
              "alias": "balance",
              "args": null,
              "kind": "ScalarField",
              "name": "quantity",
              "storageKey": null
            },
            {
              "alias": "assetImage",
              "args": null,
              "kind": "ScalarField",
              "name": "image",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": "nativeFunds",
          "args": [
            (v0/*: any*/),
            {
              "kind": "Variable",
              "name": "symbol",
              "variableName": "nativeSymbol"
            }
          ],
          "concreteType": "WalletFundsType",
          "kind": "LinkedField",
          "name": "fundsOf",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "quantity",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": (v1/*: any*/),
      "concreteType": "PaymentAssetType",
      "kind": "LinkedField",
      "name": "paymentAsset",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "decimals",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "8c3081e9776fae71bfbd6a8ed8d18398";

export default node;
