import { flatten } from "lodash"
import { useCallback } from "react"
import { useRelayEnvironment } from "react-relay"
import { fetchQuery, graphql } from "relay-runtime"
import type { useENSFromAddressResolverQuery } from "@/lib/graphql/__generated__/useENSFromAddressResolverQuery.graphql"

export const useENSFromAddressResolver = () => {
  const environment = useRelayEnvironment()

  return useCallback(
    async (address: string) => {
      const data = await fetchQuery<useENSFromAddressResolverQuery>(
        environment,
        graphql`
          query useENSFromAddressResolverQuery($addresses: [AddressScalar!]!) {
            getAccountsByAddresses(addresses: $addresses) {
              names {
                name
                type
              }
            }
          }
        `,
        { addresses: [address] },
      ).toPromise()

      const enss = flatten(
        data?.getAccountsByAddresses.map(account => account.names) ?? [],
      ).filter(name => name.type === "ens")

      if (enss.length === 0) {
        return
      }

      return {
        address,
        ens: enss[0].name,
      }
    },
    [environment],
  )
}
