import { useTranslate } from "@opensea/next-translate"
import { useCallback } from "react"
import { bn, isValidNumericInput } from "@/lib/helpers/numberUtils"

export const useIsValidAmount = (
  paymentAssetDecimals: number,
  message?: string,
) => {
  const t = useTranslate("common")

  return useCallback(
    (value?: string) => {
      const amount = bn(value ?? 0)

      if (
        amount.isNaN() ||
        !isValidNumericInput(value ?? "", paymentAssetDecimals) ||
        amount.isLessThanOrEqualTo(0)
      ) {
        return (
          message ??
          t("useIsValidAmount.message", "Please enter a valid amount.")
        )
      }

      return true
    },
    [paymentAssetDecimals, message, t],
  )
}
