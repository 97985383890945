import { classNames } from "@opensea/ui-kit"
import React from "react"

type ModalBodyProps = {
  children: React.ReactNode
  className?: string
}

export function ModalBody(props: ModalBodyProps) {
  const { children, className } = props

  return <div className={classNames("p-6", className)}>{children}</div>
}
