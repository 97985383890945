import { graphql } from "relay-runtime"

export const SEND_TOKEN_QUERY = graphql`
  query SendTokenModalQuery(
    $address: AddressScalar!
    $chain: ChainScalar!
    $symbol: String!
    $nativeSymbol: String!
  ) {
    ...TransactionDetailsFragment
      @arguments(
        address: $address
        chain: $chain
        symbol: $symbol
        nativeSymbol: $nativeSymbol
      )
    ...ConfirmTransactionFragment
      @arguments(address: $address, chain: $chain, symbol: $symbol)
  }
`
