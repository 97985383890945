import { useTranslate } from "@opensea/next-translate"
import { CenterAligned, classNames, UnstyledButton } from "@opensea/ui-kit"
import { ArrowForward } from "@opensea/ui-kit/icons"
import React, { useCallback } from "react"
import { useForm } from "react-hook-form"
import type { PreloadedQuery } from "react-relay"
import { graphql, useFragment, usePreloadedQuery } from "react-relay"
import { POLYGON_BRIDGE_LINK } from "@/constants"
import { Button } from "@/design-system/Button"
import { Form } from "@/design-system/Form"
import type { ChainIdentifier } from "@/hooks/useChains/types"
import type { WrapTokenModalQuery } from "@/lib/graphql/__generated__/WrapTokenModalQuery.graphql"
import type {
  WrapTransactionDetailsFragment$data,
  WrapTransactionDetailsFragment$key,
} from "@/lib/graphql/__generated__/WrapTransactionDetailsFragment.graphql"
import { isPolygon, isSolana } from "@/lib/helpers/chainUtils"
import { bn, display } from "@/lib/helpers/numberUtils"
import { useIsValidAmount } from "../../hooks/useIsValidAmount"
import {
  useBalanceForGasError,
  useValidateBalance,
} from "../../hooks/useValidateBalance"
import { QuantityInput } from "../QuantityInput"
import type { DirectionValueType } from "./WrapUnwrapRadioButton.react"
import { WrapUnwrapRadioButton } from "./WrapUnwrapRadioButton.react"
import { WRAP_TOKEN_QUERY } from "./WrapTokenModalQuery"

export type WrapTransactionData = {
  quantity: string
  direction: DirectionValueType
}

type WrapTransactionDetailsProps = {
  queryRef: PreloadedQuery<WrapTokenModalQuery>
  chain: ChainIdentifier
  symbol: string
  onSubmit: (data: WrapTransactionData) => void
  estimatedGas: string | null
  estimatingGas: boolean
  initialValues?: {
    quantity: string
    direction: DirectionValueType
  }
}

export function WrapTransactionDetails({
  queryRef,
  ...rest
}: WrapTransactionDetailsProps) {
  const data = usePreloadedQuery(WRAP_TOKEN_QUERY, queryRef)

  const {
    wallet: { baseFund, wrappedFund },
    basePaymentAsset,
    wrappedPaymentAsset,
  } = useFragment<WrapTransactionDetailsFragment$key>(
    graphql`
      fragment WrapTransactionDetailsFragment on Query
      @argumentDefinitions(
        address: { type: "AddressScalar!" }
        baseSymbol: { type: "String!" }
        baseChain: { type: "ChainScalar!" }
        wrappedSymbol: { type: "String!" }
        wrappedChain: { type: "ChainScalar!" }
      ) {
        wallet(address: $address) {
          baseFund: fundsOf(symbol: $baseSymbol, chain: $baseChain) {
            balance: quantity
            symbol
          }
          wrappedFund: fundsOf(symbol: $wrappedSymbol, chain: $wrappedChain) {
            balance: quantity
            symbol
          }
        }

        basePaymentAsset: paymentAsset(symbol: $baseSymbol, chain: $baseChain) {
          decimals
        }

        wrappedPaymentAsset: paymentAsset(
          symbol: $wrappedSymbol
          chain: $wrappedChain
        ) {
          decimals
        }
      }
    `,
    data,
  )

  return (
    <WrapTransactionDetailsBase
      {...rest}
      baseFund={baseFund}
      basePaymentAsset={basePaymentAsset}
      wrappedFund={wrappedFund}
      wrappedPaymentAsset={wrappedPaymentAsset}
    />
  )
}

type WalletQueryData = WrapTransactionDetailsFragment$data["wallet"]

type BasePaymentAssetType =
  WrapTransactionDetailsFragment$data["basePaymentAsset"]

type WrappedPaymentAssetType =
  WrapTransactionDetailsFragment$data["wrappedPaymentAsset"]

export function WrapTransactionDetailsBase({
  baseFund,
  basePaymentAsset,
  chain,
  estimatedGas,
  estimatingGas,
  initialValues,
  onSubmit,
  wrappedFund,
  wrappedPaymentAsset,
}: Omit<WrapTransactionDetailsProps, "queryRef" | "symbol"> & {
  baseFund: WalletQueryData["baseFund"]
  wrappedFund: WalletQueryData["wrappedFund"]
  basePaymentAsset: BasePaymentAssetType
  wrappedPaymentAsset: WrappedPaymentAssetType
}) {
  const t = useTranslate("assets")

  const form = useForm({
    mode: "onChange",
    defaultValues: initialValues ?? {
      quantity: "",
      direction: "Wrap" as DirectionValueType,
    },
  })

  const { direction, quantity } = form.watch()

  const setDirection = useCallback(
    (newDirection: DirectionValueType) => {
      form.setValue("direction", newDirection, {
        shouldValidate: true,
      })
      form.setValue("quantity", "")
    },
    [form],
  )

  const toggleDirection = useCallback(() => {
    setDirection(direction === "Wrap" ? "Unwrap" : "Wrap")
  }, [direction, setDirection])

  const handlePolygonUnwrapClick = useCallback(() => {
    window.open(POLYGON_BRIDGE_LINK, "_blank", "noreferrer")
  }, [])

  const fund = direction === "Wrap" ? baseFund : wrappedFund
  const paymentAsset =
    direction === "Wrap" ? basePaymentAsset : wrappedPaymentAsset

  const validateIsNumericInput = useIsValidAmount(paymentAsset.decimals)
  const validateHasSufficientBalance = useValidateBalance({
    balance: fund.balance,
    estimatedGas: estimatedGas ?? 0,
    symbol: fund.symbol,
    chain,
  })
  const balanceForGasError = useBalanceForGasError({
    nativeBalance: baseFund.balance,
    estimatedGas: estimatedGas ?? 0,
    chain,
  })

  const wrapDisabled = isSolana(chain)
  const unwrapDisabled = isPolygon(chain)

  return (
    <Form
      className="flex flex-1 flex-col justify-between"
      onSubmit={form.handleSubmit(values => {
        onSubmit(values)
      })}
    >
      <div className="flex flex-col space-y-4 px-2">
        <div className="relative my-6 flex">
          <div
            className="mr-2 flex-1 rounded-large bg-component-gray-1 px-4 py-6 text-center"
            data-testid="WrapTransactionDetails--wallet-balance"
          >
            <p className="font-semibold">
              {display(bn(baseFund.balance))} {baseFund.symbol}{" "}
            </p>
            <span className="text-secondary">
              {t("wrapTransactionDetails.walletBalance", "Wallet balance")}
            </span>
          </div>
          <div
            className="flex-1 rounded-large bg-component-gray-1 px-4 py-6 text-center"
            data-testid="WrapTransactionDetails--offer-balance"
          >
            <p className="font-semibold">
              {display(bn(wrappedFund.balance))} {wrappedFund.symbol}{" "}
            </p>
            <span className="text-secondary">
              {t("wrapTransactionDetails.offerBalance", "Offer balance")}
            </span>
          </div>
          <div className="absolute inset-0 flex items-center justify-center">
            <CenterAligned asChild>
              <UnstyledButton
                className={classNames(
                  "size-9 rounded-circle bg-elevation-1 p-1.5 shadow-none transition-[transform,box-shadow] hover:shadow-button",
                  {
                    "rotate-180": direction === "Unwrap",
                  },
                )}
                disabled={wrapDisabled || unwrapDisabled}
                onClick={toggleDirection}
                type="button"
              >
                <ArrowForward size={22} />
              </UnstyledButton>
            </CenterAligned>
          </div>
        </div>
        <WrapUnwrapRadioButton
          onChange={setDirection}
          onUnwrapClick={
            isPolygon(chain) ? handlePolygonUnwrapClick : undefined
          }
          unwrapDisabled={unwrapDisabled}
          unwrapLabel={
            isPolygon(chain)
              ? t(
                  "wrapTransactionDetails.bridgeToEthereum",
                  "Bridge to Ethereum",
                )
              : t("unwrap", "Unwrap")
          }
          value={direction}
          wrapDisabled={wrapDisabled}
          wrapLabel={
            isPolygon(chain)
              ? t("wrapTransactionDetails.bridgeToPolygon", "Bridge to Polygon")
              : t("wrapTransactionDetails.wrap", "Wrap")
          }
        />

        <QuantityInput
          balance={fund.balance}
          chain={chain}
          error={form.formState.errors.quantity?.message ?? balanceForGasError}
          estimatedGas={estimatedGas}
          estimatingGas={estimatingGas}
          onValueChange={value => {
            form.setValue("quantity", value, {
              shouldValidate: true,
            })
          }}
          operation={direction}
          setError={message => {
            form.setError("quantity", { message })
          }}
          symbol={fund.symbol}
          value={quantity}
          {...form.register("quantity", {
            required: t(
              "transactionDetailsBase.errors.quantityRequired",
              "Please enter a valid amount.",
            ),
            validate: {
              validateIsNumericInput,
              validateHasSufficientBalance,
            },
          })}
        />
      </div>
      <Button
        className="w-full justify-center"
        disabled={!form.formState.isValid || estimatingGas}
        isLoading={form.formState.isSubmitting}
        type="submit"
      >
        {t("next", "Next")}
      </Button>
    </Form>
  )
}
