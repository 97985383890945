import { useTranslate } from "@opensea/next-translate"
import { classNames } from "@opensea/ui-kit"
import Image from "next/image"
import React from "react"
import type { PreloadedQuery } from "react-relay"
import { graphql, useFragment, usePreloadedQuery } from "react-relay"
import { useAsyncFn } from "react-use"
import { TokenAmount } from "@/components/common/TokenAmount/TokenAmount.react"
import { Button } from "@/design-system/Button"
import { Skeleton } from "@/design-system/Skeleton.react"
import type { ChainIdentifier } from "@/hooks/useChains/types"
import { useNativeSymbol } from "@/hooks/useChains/useNativeSymbol"
import type { ConfirmTransactionFragment$key } from "@/lib/graphql/__generated__/ConfirmTransactionFragment.graphql"
import type { SendTokenModalQuery } from "@/lib/graphql/__generated__/SendTokenModalQuery.graphql"
import { getEquallySizedChainLogo } from "@/lib/helpers/chainUtils"
import { bn, display, displayFiat } from "@/lib/helpers/numberUtils"
import { SEND_TOKEN_QUERY } from "./SendTokenModalQuery"

type ConfirmTransactionProps = {
  chain: ChainIdentifier
  queryRef: PreloadedQuery<SendTokenModalQuery>
  symbol: string
  quantity: string
  estimatedGas: string | null
  estimatingGas: boolean
  onConfirm: () => Promise<void> | void
}

export function ConfirmTransaction(props: ConfirmTransactionProps) {
  const { queryRef, ...rest } = props

  const data = usePreloadedQuery(SEND_TOKEN_QUERY, queryRef)

  const {
    paymentAsset: { usdPrice },
    wallet: {
      fundsOf: { assetImage },
    },
  } = useFragment<ConfirmTransactionFragment$key>(
    graphql`
      fragment ConfirmTransactionFragment on Query
      @argumentDefinitions(
        address: { type: "AddressScalar!" }
        chain: { type: "ChainScalar!" }
        symbol: { type: "String!" }
      ) {
        paymentAsset(symbol: $symbol, chain: $chain) {
          usdPrice
        }

        wallet(address: $address) {
          fundsOf(symbol: $symbol, chain: $chain) {
            assetImage: image
          }
        }
      }
    `,
    data,
  )

  return (
    <ConfirmTransactionBase
      {...rest}
      assetImage={assetImage}
      usdPrice={usdPrice}
    />
  )
}

export function ConfirmTransactionBase({
  chain,
  estimatedGas,
  estimatingGas,
  assetImage,
  quantity,
  symbol,
  usdPrice,
  onConfirm,
}: Omit<ConfirmTransactionProps, "queryRef"> & {
  assetImage: string | null | undefined
  usdPrice?: string
}) {
  const nativeSymbol = useNativeSymbol(chain)
  const ChainLogo = getEquallySizedChainLogo(chain)
  const t = useTranslate("assets")

  const isNativeSend = symbol === nativeSymbol

  const [{ loading }, confirmTransaction] = useAsyncFn(async () => {
    await onConfirm()
  })

  const renderAmount = () => {
    const usdValue = usdPrice ? bn(usdPrice).times(quantity) : bn(0)
    return (
      <div className="flex items-start justify-between">
        <div className="flex items-center space-x-2">
          {assetImage ? (
            <Image alt="" height={20} src={assetImage} width={20} />
          ) : null}
          {!assetImage && ChainLogo ? <ChainLogo width={32} /> : null}
          <span className="font-semibold capitalize text-primary">
            {symbol}
          </span>
        </div>
        <div className="text-right">
          <div
            className={classNames("text-primary", {
              "font-semibold ": !isNativeSend,
            })}
            data-testid="ConfirmTransaction--amount"
          >
            <TokenAmount amount={quantity} symbol={symbol} />
          </div>

          {!isNativeSend && (
            <span
              className="text-secondary"
              data-testid="ConfirmTransaction--amount-fiat"
            >
              {usdPrice ? displayFiat(usdValue) : null}
            </span>
          )}
        </div>
      </div>
    )
  }

  const renderGas = () => {
    if (!estimatedGas) {
      return null
    }

    const usdGasPrice = usdPrice ? bn(usdPrice).times(estimatedGas) : bn(0)

    return (
      <div className="flex items-start justify-between">
        <div className="font-semibold capitalize text-primary">
          {t("gas", "Gas")}
        </div>
        <div className="text-right ">
          <div
            className={classNames("text-primary", {
              "font-semibold ": !isNativeSend,
            })}
            data-testid="ConfirmTransaction--gas"
          >
            {estimatingGas ? (
              <Skeleton className="my-0.5 h-5 w-24" />
            ) : (
              <>
                {display(estimatedGas)} {nativeSymbol.toUpperCase()}
              </>
            )}
          </div>
          {!isNativeSend && (
            <div
              className="text-secondary"
              data-testid="ConfirmTransaction--gas-fiat"
            >
              {displayFiat(usdGasPrice)}
            </div>
          )}
        </div>
      </div>
    )
  }

  const renderNativeTotal = () => {
    const total =
      symbol === nativeSymbol
        ? bn(quantity).plus(estimatedGas ?? "0")
        : bn(quantity)

    return (
      <div className="flex items-start justify-between">
        <span className="font-semibold text-primary ">
          {t("total", "Total")}
        </span>
        <div className="text-right text-primary">
          <div
            className="font-semibold text-primary"
            data-testid="ConfirmTransaction--total"
          >
            {display(total)} {symbol.toUpperCase()}
          </div>
          <div
            className="text-secondary"
            data-testid="ConfirmTransaction--total-fiat"
          >
            {usdPrice
              ? displayFiat(bn(usdPrice).times(total).toString())
              : null}
          </div>
        </div>
      </div>
    )
  }

  const renderNativeCurrencyDetails = () => {
    return (
      <div className="flex flex-col rounded-medium bg-component-gray-1 p-2">
        <div className="flex flex-col space-y-8 border-b border-level-1 px-2 py-5">
          {renderAmount()}
          {renderGas()}
        </div>
        <div className="px-2 pb-4 pt-6">{renderNativeTotal()}</div>
      </div>
    )
  }

  const renderWrappedCurrencyDetails = () => {
    return (
      <>
        <div className="flex flex-col rounded-medium bg-component-gray-1 px-4 py-6">
          {renderAmount()}
        </div>
        <div className="flex flex-col rounded-medium bg-component-gray-1 px-4 py-6">
          {renderGas()}
        </div>
      </>
    )
  }

  return (
    <div className="flex flex-1 flex-col justify-between">
      <div className="flex flex-col space-y-4">
        {isNativeSend
          ? renderNativeCurrencyDetails()
          : renderWrappedCurrencyDetails()}
      </div>

      <Button
        className="w-full justify-center"
        disabled={loading || estimatingGas}
        isLoading={loading}
        onClick={() => confirmTransaction()}
      >
        {t("confirm", "Confirm")}
      </Button>
    </div>
  )
}
