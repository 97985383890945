import { BigNumber } from "bignumber.js"
import { useCallback, useEffect, useState } from "react"
import {
  SMALLEST_DISPLAYED_QUANTITY,
  bn,
  getMaxDisplayedDecimals,
} from "@/lib/helpers/numberUtils"
import type { EstimateGasProps } from "@/providers/Vessel00/types"
import { useEstimateGas } from "@/providers/Wallet/selectors"
import type { ChainIdentifier } from "./useChains/types"

const GAS_ESTIMATE_UPDATE_INTERVAL = 15_000
const GAS_ESTIMATION_TO_ADDRESS = "0x000000000000000000000000000000000000dEaD"

/**
 * @returns function that uses parent frame to estimate the total gas fee required for the transaction (in ETH)
 */
export const useGasEstimate = (
  {
    action,
    chain,
    symbol,
  }: {
    action: EstimateGasProps["actionForEstimate"]["action"]
    chain: ChainIdentifier
    symbol: string
  },
  options: {
    skip?: boolean
  } = {
    skip: false,
  },
) => {
  const { skip } = options

  const [gasEstimate, setGasEstimate] = useState<BigNumber | null>(null)
  const [estimatingGas, setEstimatingGas] = useState(true)

  const _estimateGas = useEstimateGas()

  const estimateGas = useCallback(async () => {
    setEstimatingGas(true)

    const result = await _estimateGas({
      actionForEstimate: {
        type: "action",
        action,
        chain,
        symbol,
        toAddress: GAS_ESTIMATION_TO_ADDRESS,
        quantity: SMALLEST_DISPLAYED_QUANTITY.toString(),
      },
    })

    setGasEstimate(
      bn(result.gasEstimate).decimalPlaces(
        getMaxDisplayedDecimals(symbol),
        BigNumber.ROUND_UP,
      ),
    )

    setEstimatingGas(false)
  }, [_estimateGas, action, chain, symbol])

  useEffect(() => {
    if (skip) {
      setGasEstimate(null)
      setEstimatingGas(false)
      return
    }

    estimateGas()

    const gasPollingInterval = setInterval(
      estimateGas,
      GAS_ESTIMATE_UPDATE_INTERVAL,
    )

    return () => {
      clearInterval(gasPollingInterval)
    }
  }, [estimateGas, skip])

  return {
    gasEstimate: gasEstimate?.toString() ?? null,
    estimatingGas,
  }
}
