import { useRouter } from "next/router"
import React, { useMemo } from "react"
import { PAGE_CONTEXT_SERVER_DEFAULTS } from "../../constants"
import { TrackingContextProvider } from "../../TrackingContext.react"
import { PageViewTracker } from "./PageViewTracker.react"
import type { PageName } from "./types"

type PageContext = {
  name: PageName
  path: string
  queryString: string
  title: string
  url: string
}

export type PageTrackingContext = {
  Page: PageContext
}

type PageTrackingContextProviderProps<T extends PageName> = {
  name: T
  /**
   * This prop should be used with data from the initial page load to ensure only one page view event
   * is triggered per page load.
   *
   * If data prop is supplied, the page view event will be tracked only once data is loaded (!= null).
   * This ensures other tracking contexts that rely on the data are ready when the event is tracked.
   * Whenever the data prop changes, additional page view events are tracked.
   *
   * If data prop is not supplied, the page view event will be tracked immediately.
   */
  data?: Record<string, unknown> | null
  children: React.ReactNode
}

export function PageTrackingContextProvider<T extends PageName>({
  name: pageName,
  data,
  children,
}: PageTrackingContextProviderProps<T>) {
  const router = useRouter()
  const pathParts = router.asPath.split("?")
  const path = pathParts[0]
  const queryString = pathParts[1]

  const properties: PageContext = useMemo(() => {
    const clientProperties =
      typeof window === "undefined"
        ? PAGE_CONTEXT_SERVER_DEFAULTS
        : {
            title: document.title,
            url: window.location.href,
          }

    return {
      name: pageName,
      path,
      queryString,
      ...clientProperties,
    }
  }, [pageName, path, queryString])

  return (
    <TrackingContextProvider name="Page" properties={properties}>
      <PageViewTracker data={data} name={pageName} />
      {children}
    </TrackingContextProvider>
  )
}
