import { classNames } from "@opensea/ui-kit"
import React, { forwardRef } from "react"
import type { FormControlProps } from "@/design-system/FormControl"
import { FormControl } from "@/design-system/FormControl"

export type FormProps = JSX.IntrinsicElements["form"] & {
  ref?: React.RefObject<HTMLFormElement>
}

const FormBase = forwardRef<HTMLFormElement, FormProps>(function FormBase(
  { className, ...props },
  ref,
) {
  return (
    <form
      {...props}
      className={classNames("flex flex-col space-y-6", className)}
      ref={ref}
    />
  )
})

const StyledFormControl = forwardRef<HTMLDivElement, FormControlProps>(
  function StyledFormControl({ className, ...props }, ref) {
    return (
      <FormControl {...props} className={classNames(className)} ref={ref} />
    )
  },
)

export const Form = Object.assign(FormBase, {
  Control: StyledFormControl,
})
