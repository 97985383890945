import { useTranslate } from "@opensea/next-translate"
import { classNames } from "@opensea/ui-kit"
import { CheckCircleFilled, Error as ErrorIcon } from "@opensea/ui-kit/icons"
import React, { useCallback, useState } from "react"
import type { InputProps } from "@/design-system/Input"
import { Input } from "@/design-system/Input"
import { Loader } from "@/design-system/Loader"
import { useAddressFromENSResolver } from "@/hooks/useAddressFromENSResolver"
import { useChains } from "@/hooks/useChains"
import type { ChainIdentifier } from "@/hooks/useChains/types"
import { useENSFromAddressResolver } from "@/hooks/useENSFromAddressResolver"
import {
  isValidAddress,
  isValidEvmAddress,
  isValidSolanaAddress,
} from "@/lib/helpers/address"
import { truncateAddress } from "@/lib/helpers/address/format"
import { isQualifiedEvmName } from "@/lib/helpers/ens"
import { useConnectedAddress } from "@/providers/Wallet/selectors"

const useIsValidAddress = (chain?: ChainIdentifier) => {
  const { isEvmChain } = useChains()
  return useCallback(
    (address: string) => {
      if (!chain) {
        return isValidAddress(address)
      }
      if (isEvmChain(chain)) {
        return isValidEvmAddress(address)
      }
      return isValidSolanaAddress(address)
    },
    [chain, isEvmChain],
  )
}

const useIsValidName = (chain?: ChainIdentifier) => {
  const { isEvmChain } = useChains()
  return useCallback(
    (address: string) => {
      if (!chain || isEvmChain(chain)) {
        return isQualifiedEvmName(address)
      }
      return false
    },
    [chain, isEvmChain],
  )
}

type AddressInputProps = Omit<InputProps, "value"> & {
  setError: (error?: string) => void
  onAddressChange: (address: string) => void
  address: string | undefined
  chain?: ChainIdentifier
}

export function AddressInput(props: AddressInputProps) {
  const {
    error,
    setError,
    id,
    onFocus,
    onBlur,
    onAddressChange,
    address,
    chain,
    ...rest
  } = props

  const t = useTranslate("assets")
  const [innerValue, setInnerValue] = useState<string>(address ?? "")
  const [addressFocused, setAddressFocused] = useState(false)

  const [resolvedAddress, setResolvedAddress] = useState("")
  const [resolvedENS, setResolvedENS] = useState("")
  const [isValidating, setIsValidating] = useState(false)

  const connectedWalletAddress = useConnectedAddress()
  const addressFromENSResolver = useAddressFromENSResolver()
  const ensFromAddressResolver = useENSFromAddressResolver()

  const isValidAddress = useIsValidAddress(chain)
  const isValidName = useIsValidName(chain)

  const resolveAddressFromQualifiedName = useCallback(
    async (nextValue: string) => {
      try {
        const result = await addressFromENSResolver(nextValue)

        if (!result) {
          return
        }

        setResolvedENS(result.ens)
        setResolvedAddress(result.address)

        if (result.address === connectedWalletAddress) {
          setError(
            t(
              "addressInput.error.selfAddress",
              "You can't send tokens to yourself",
            ),
          )
          return
        }
        onAddressChange(result.address)
      } catch (error) {
        if (error instanceof Error) {
          setError(error.message)
        } else {
          setError(t("addressInput.error.unknown", "Invalid address"))
        }
      }
    },
    [
      addressFromENSResolver,
      connectedWalletAddress,
      onAddressChange,
      setError,
      t,
    ],
  )

  const resolveAccountFromAddress = useCallback(
    async (nextValue: string) => {
      onAddressChange(nextValue)

      const result = await ensFromAddressResolver(nextValue)

      if (!result) {
        return
      }

      setResolvedENS(result.ens)
      setResolvedAddress(result.address)
      onAddressChange(result.address)
    },
    [ensFromAddressResolver, onAddressChange],
  )

  const handleBlur = async (event: React.FocusEvent<HTMLInputElement>) => {
    const nextValue = event.target.value

    setAddressFocused(false)
    onBlur?.(event)

    setResolvedAddress("")
    setResolvedENS("")

    if (nextValue === connectedWalletAddress) {
      setError(
        t(
          "addressInput.error.selfAddress",
          "You can't send tokens to yourself",
        ),
      )
      return
    }

    setIsValidating(true)

    if (isValidName(nextValue)) {
      await resolveAddressFromQualifiedName(nextValue)
    } else if (isValidAddress(nextValue)) {
      onAddressChange(nextValue)
      await resolveAccountFromAddress(nextValue)
    } else {
      setError(
        t(
          "addressInput.error.invalidAddressOrENS",
          "Invalid address or ENS name.",
        ),
      )
    }

    setIsValidating(false)
  }

  const renderIndicator = () => {
    if (isValidating) {
      return <Loader />
    }

    if (error) {
      return <ErrorIcon fill="error" size={24} />
    }

    if (address) {
      return <CheckCircleFilled fill="success" size={24} />
    }

    return null
  }

  const renderValue = () => {
    if (
      addressFocused ||
      isValidating ||
      !(isValidAddress(innerValue) || isValidName(innerValue))
    ) {
      return innerValue
    }

    const truncatedAddress = truncateAddress((resolvedAddress || address) ?? "")

    if (resolvedENS) {
      return `${resolvedENS} (${truncatedAddress})`
    }

    return truncatedAddress
  }

  return (
    <Input
      {...rest}
      autoComplete="off"
      endEnhancer={
        <div
          className={classNames("flex items-center rounded-default pl-1", {
            "group-focus-within:hidden": isValidating,
          })}
        >
          {renderIndicator()}
        </div>
      }
      error={isValidating ? false : error}
      id={id}
      onBlur={handleBlur}
      onChange={e => {
        setError()
        onAddressChange("")
        setInnerValue(e.target.value)
      }}
      onFocus={e => {
        setAddressFocused(true)
        onFocus?.(e)
      }}
      placeholder={t("addressInput.placeholder", "Recipient's wallet address")}
      value={renderValue()}
    />
  )
}
