import { useTranslate } from "@opensea/next-translate"
import { useCallback } from "react"
import { useRelayEnvironment } from "react-relay"
import { fetchQuery, graphql } from "relay-runtime"
import type { useAddressFromENSResolverQuery } from "@/lib/graphql/__generated__/useAddressFromENSResolverQuery.graphql"

export const useAddressFromENSResolver = () => {
  const t = useTranslate("common")
  const environment = useRelayEnvironment()

  return useCallback(
    async (ens: string) => {
      const data = await fetchQuery<useAddressFromENSResolverQuery>(
        environment,
        graphql`
          query useAddressFromENSResolverQuery($name: String!) {
            accountHelpers {
              address: resolveEnsName(name: $name)
            }
          }
        `,
        { name: ens },
      ).toPromise()

      if (!data?.accountHelpers.address) {
        throw new Error(
          t(
            "useAddressFromENSResolver.addressNotFound",
            "Could not resolve ENS name",
          ),
        )
      }

      return {
        address: data.accountHelpers.address,
        ens,
      }
    },
    [environment, t],
  )
}
