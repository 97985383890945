import React from "react"
import { PageTrackingContextProvider } from "@/lib/analytics/TrackingContext/contexts/PageTrackingContext/PageTrackingContext.react"
import { useWalletLayoutHeader } from "@/providers/WalletLayout/hooks"
import { UIProviders } from "@/providers/UIProviders"
import { Assets } from "../Assets"
import { WalletBalance } from "../WalletBalance"

function CryptoPageHeader() {
  const WalletHeader = useWalletLayoutHeader()

  return (
    <WalletHeader>
      <WalletBalance />
    </WalletHeader>
  )
}

export function CryptoPage() {
  return (
    <UIProviders>
      <PageTrackingContextProvider name="Assets">
        <CryptoPageHeader />
        <Assets className="flex-1" />
      </PageTrackingContextProvider>
    </UIProviders>
  )
}
