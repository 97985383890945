import { useTranslate } from "@opensea/next-translate"
import {
  classNames,
  Flex,
  FlexColumn,
  Skeleton,
  SpaceBetween,
  UnstyledButton,
} from "@opensea/ui-kit"
import { Tooltip, useIsHydrated } from "@opensea/ui-kit/client"
import { Check, Autorenew, Add, Send, SwapHoriz } from "@opensea/ui-kit/icons"
import Image from "next/image"
import React, { useMemo, useState } from "react"
import { range } from "lodash"
import { TokenAmount } from "@/components/common/TokenAmount"
import { Button } from "@/design-system/Button"
import { Text } from "@/design-system/Text"
import { useChains } from "@/hooks/useChains"
import type { ChainIdentifier } from "@/hooks/useChains/types"
import { useIsMobile } from "@/hooks/useIsMobile"
import {
  getEquallySizedChainLogo,
  getEthereumChain,
  isPolygon,
} from "@/lib/helpers/chainUtils"
import { bn, displayFiat } from "@/lib/helpers/numberUtils"
import { useIsKeyboardNavigationActive } from "@/providers/KeyboardNavigation"
import {
  useAddFunds,
  useConnectedChain,
  useRefreshFunds,
} from "@/providers/Wallet/selectors"
import {
  useAllWalletBalances,
  useRefreshWalletBalances,
} from "@/providers/WalletBalanceProvider"
import { EmptyWallet } from "../EmptyWallet/EmptyWallet.react"
import { SendTokenModal } from "../SendTokenModal"
import { WrapTokenModal } from "../WrapTokenModal"
import { AssetProvider } from "./AssetProvider.react"
import { useStartAssetAction } from "./hooks"
import type { ItemAction } from "./MobileItemActions.react"
import { MobileItemActions } from "./MobileItemActions.react"

type AssetProps = {
  className?: string
}

export function Assets({ className }: AssetProps) {
  const t = useTranslate("assets")
  const isHydrated = useIsHydrated()
  const [isHovering, setIsHovering] = useState(false)
  const isMobile = useIsMobile()
  const refreshFunds = useRefreshFunds()
  const refreshWalletBalances = useRefreshWalletBalances()

  const [refreshingBalance, setRefreshingBalance] = useState(false)
  const [refreshedBalance, setRefreshedBalance] = useState(false)
  const showRefreshButton =
    isHovering || isMobile || refreshingBalance || refreshedBalance

  const handleRefresh = async () => {
    setRefreshedBalance(false)
    setRefreshingBalance(true)

    await refreshFunds()
    await refreshWalletBalances()

    setRefreshedBalance(true)
    setRefreshingBalance(false)

    setTimeout(() => {
      setRefreshedBalance(false)
    }, 1500)
  }

  const connectedChain = useConnectedChain() ?? getEthereumChain()
  const { balances, ready } = useAllWalletBalances(connectedChain)
  // Check isHydrated to prevent hydration errors due to server not having access to wallet balances
  const balancesReady = ready && isHydrated

  const { getNativeCurrencySymbol, getWrappedCurrencySymbol } = useChains()

  const nativeCurrencyBalance = balances.find(
    b =>
      b.currency.symbol === getNativeCurrencySymbol(connectedChain) &&
      !bn(b.quantity).isZero(),
  )
  const wrappedCurrencyBalance = balances.find(
    b =>
      b.currency.symbol === getWrappedCurrencySymbol(connectedChain) &&
      !bn(b.quantity).isZero(),
  )
  const otherBalances = balances.filter(
    b =>
      b.currency.symbol !== getNativeCurrencySymbol(connectedChain) &&
      b.currency.symbol !== getWrappedCurrencySymbol(connectedChain) &&
      !bn(b.quantity).isZero(),
  )

  const renderContent = () => {
    if (!balancesReady) {
      return (
        <>
          <Flex className="h-18 items-center px-6 pb-4 pt-6">
            <Text.Heading size="medium">
              {t("assets.tokens", "Tokens")}
            </Text.Heading>
          </Flex>
          {range(3).map(index => (
            <FundItemSkeleton key={index} />
          ))}
        </>
      )
    }

    if (balances.length && balances.some(b => !bn(b.quantity).isZero())) {
      return (
        <>
          <Flex
            className="h-18 items-center px-6 pb-4 pt-6"
            onMouseEnter={() => {
              setIsHovering(true)
            }}
            onMouseLeave={() => {
              setIsHovering(false)
            }}
          >
            <Text.Heading size="medium">
              {t("assets.tokens", "Tokens")}
            </Text.Heading>
            {showRefreshButton ? (
              <UnstyledButton
                className="ml-2 p-1"
                disabled={refreshingBalance}
                onClick={handleRefresh}
              >
                {refreshedBalance ? (
                  <Check fill="gray-1" size={20} />
                ) : (
                  <Autorenew
                    className={classNames(refreshingBalance && "animate-spin")}
                    fill="gray-1"
                    size={20}
                  />
                )}
              </UnstyledButton>
            ) : null}
          </Flex>
          {nativeCurrencyBalance ? (
            <FundItem
              {...nativeCurrencyBalance.currency}
              name={
                nativeCurrencyBalance.currency.symbol === "ETH"
                  ? t("assets.ethereum", "Ethereum")
                  : null
              }
              quantity={nativeCurrencyBalance.quantity}
            />
          ) : null}
          {wrappedCurrencyBalance ? (
            <FundItem
              {...wrappedCurrencyBalance.currency}
              name="Offer balance"
              quantity={wrappedCurrencyBalance.quantity}
            />
          ) : null}
          {otherBalances.map(b => {
            return (
              <FundItem
                key={b.currency.symbol}
                {...b.currency}
                name={null}
                quantity={b.quantity}
              />
            )
          })}
        </>
      )
    }

    return <EmptyWallet />
  }

  return (
    <AssetProvider>
      <div className={classNames("rounded-t-large pb-3", className)}>
        {renderContent()}
      </div>
      <SendTokenModal />
      <WrapTokenModal />
    </AssetProvider>
  )
}

function FundItemSkeleton() {
  return (
    <div className="mx-3">
      <SpaceBetween className="m-3 items-center">
        <Flex className="items-center gap-4">
          <Skeleton.Block className="size-[60px] rounded-lg" />
          <Skeleton.Line className="w-[80px]" />
        </Flex>
        <FlexColumn className="items-end gap-1">
          <Skeleton.Line className="w-[100px]" />
          <Skeleton.Line className="h-[12px] w-[80px]" />
        </FlexColumn>
      </SpaceBetween>
    </div>
  )
}

type FundsItemProps = {
  symbol: string
  quantity: string
  usdPrice?: string | null
  chain: ChainIdentifier
  imageUrl?: string | null
  name?: string | null
}

function FundItem({
  symbol,
  quantity,
  usdPrice,
  chain,
  name,
  imageUrl,
}: FundsItemProps) {
  const t = useTranslate("assets")
  const isMobile = useIsMobile()
  const ChainLogo = getEquallySizedChainLogo(chain)
  const startAssetAction = useStartAssetAction()
  const addFunds = useAddFunds()
  const { getNativeCurrencySymbol, getWrappedCurrencySymbol } = useChains()

  const nativeCurrencySymbol = getNativeCurrencySymbol(chain)
  const wrappedCurrencySymbol = getWrappedCurrencySymbol(chain)

  const showWrapButton = isPolygon(chain)
    ? symbol === wrappedCurrencySymbol
    : symbol === nativeCurrencySymbol || symbol === wrappedCurrencySymbol

  const navigatingWithKeyboard = useIsKeyboardNavigationActive()

  const memoizedActions = useMemo(() => {
    const actions: ItemAction[] = [
      {
        label: t("assets.addFunds", "Buy"),
        icon: <Add />,
        onClick: () => addFunds(chain, symbol),
      },
    ]

    if (showWrapButton) {
      actions.push({
        label:
          symbol === nativeCurrencySymbol
            ? t("assets.wrap", "Wrap")
            : t("assets.unwrap", "Unwrap"),
        icon: <SwapHoriz />,
        onClick: () => {
          startAssetAction({ symbol, chain }, "WRAP")
        },
      })
    }

    actions.push({
      label: t("assets.send", "Send"),
      icon: <Send />,
      onClick: () => {
        startAssetAction({ symbol, chain }, "SEND")
      },
    })

    return actions
  }, [
    addFunds,
    chain,
    nativeCurrencySymbol,
    showWrapButton,
    startAssetAction,
    symbol,
    t,
  ])

  return (
    <div className="flex flex-col px-3">
      <div
        className={classNames(
          "group relative flex items-center space-x-4 rounded-medium p-3 hover:bg-component-gray-1",
          {
            "focus-within:bg-component-gray-1": navigatingWithKeyboard,
          },
        )}
        key={symbol}
      >
        <div className="flex size-15 items-center justify-center rounded-medium bg-component-gray-1">
          {imageUrl ? (
            <Image alt="" height={32} src={imageUrl} width={32} />
          ) : null}
          {!imageUrl && ChainLogo ? <ChainLogo width={32} /> : null}
        </div>
        <div className="flex-1">
          <Text className="block" color="primary" weight="semibold">
            {name ?? symbol}
          </Text>
          {name !== null && (
            <Text className="block" color="secondary" size="small">
              {symbol}
            </Text>
          )}
        </div>
        <div
          className={classNames("text-right", {
            "group-hover:hidden": !isMobile,
            "group-focus-within:hidden": navigatingWithKeyboard,
          })}
        >
          <Text className="block" color="primary" weight="semibold">
            <TokenAmount amount={quantity} symbol={symbol} />
          </Text>
          {usdPrice ? (
            <Text className="block" color="secondary" size="small">
              {displayFiat(bn(usdPrice).times(bn(quantity)))} USD
            </Text>
          ) : null}
        </div>
        <div
          className={classNames(
            "pointer-events-none absolute inset-y-0 right-0 flex items-center space-x-1 p-3 opacity-0",
            {
              "group-hover:pointer-events-auto group-hover:opacity-100":
                !isMobile,
              "group-focus-within:pointer-events-auto group-focus-within:opacity-100":
                navigatingWithKeyboard,
            },
          )}
        >
          {memoizedActions.map(action => (
            <Tooltip
              className="z-20"
              content={action.label}
              delayDuration={300}
              key={action.label}
            >
              <span>
                <Button
                  aria-label={action.label}
                  className="bg-transparent"
                  icon={action.icon}
                  onClick={action.onClick}
                  variant="secondary"
                />
              </span>
            </Tooltip>
          ))}
        </div>
        {isMobile ? (
          <MobileItemActions
            actions={memoizedActions}
            className="relative left-1 !ml-5"
          />
        ) : null}
      </div>
    </div>
  )
}
