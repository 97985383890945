import { useTranslate } from "@opensea/next-translate"
import React from "react"
import { Button } from "@/design-system/Button"
import { useAddFunds } from "@/providers/Wallet/selectors"
import { EmptyWalletIcon } from "@/components/svgs/EmptyWalletIcon.react"

export function EmptyWallet() {
  const addFunds = useAddFunds()
  const t = useTranslate("assets")

  return (
    <div className="flex flex-col items-center justify-center space-y-4 px-14 pb-7 pt-10 text-heading-sm font-semibold">
      <EmptyWalletIcon />
      <p className="max-w-[200px] text-center">
        {t("emptyWallet.fundYourWallet", "Fund your wallet to purchase NFTs")}
      </p>
      <Button onClick={() => addFunds()}>
        {t("emptyWallet.addFundsWithCards", "Add Funds with Cards")}
      </Button>
    </div>
  )
}
