
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import { CryptoPage } from "@/features/wallet/components/CryptoPage";
import { getAppServerSideProps } from "@/lib/getAppServerSideProps";
export default CryptoPage;
const getServerSideProps = getAppServerSideProps;
export {};

    async function __Next_Translate__getServerSideProps__19190c8a086__(ctx) {
      const res = await getServerSideProps(ctx)
      return {
        
        ...res,
        props: {
          ...(res.props || {}),
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/index',
            loaderName: 'getServerSideProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getServerSideProps__19190c8a086__ as getServerSideProps }
  