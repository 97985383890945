import { useContextSelector } from "use-context-selector"
import { AssetContext } from "./AssetProvider.react"

export const useStartAssetAction = () =>
  useContextSelector(AssetContext, context => context.startAssetAction)

export const useExitAssetAction = () =>
  useContextSelector(AssetContext, context => context.exitAssetAction)

export const useAsset = () =>
  useContextSelector(AssetContext, context => context.asset)

export const useSetAsset = () =>
  useContextSelector(AssetContext, context => context.setAsset)

export const useAssetAction = () =>
  useContextSelector(AssetContext, context => context.action)
