import { useTranslate } from "@opensea/next-translate"
import { useCallback, useMemo } from "react"
import { useChains } from "@/hooks/useChains"
import type { ChainIdentifier } from "@/hooks/useChains/types"
import { GAS_ESTIMATION_BUFFER_PERCENTAGE } from "@/lib/helpers/gas"
import type { NumberInput } from "@/lib/helpers/numberUtils"
import { bn } from "@/lib/helpers/numberUtils"

type UseValidateBalanceProps = {
  balance: NumberInput
  estimatedGas: NumberInput
  chain: ChainIdentifier
  symbol: string
}

export const useValidateBalance = ({
  balance,
  estimatedGas,
  chain,
  symbol,
}: UseValidateBalanceProps) => {
  const t = useTranslate("common")
  const { getNativeCurrencySymbol } = useChains()

  return useCallback(
    (inputValue: string) => {
      const value = bn(inputValue)

      if (bn(balance).isLessThan(value)) {
        return t(
          "useValidateBalance.insufficientFunds",
          "You don't have enough {{symbol}}",
          { symbol },
        )
      }

      const estimatedGasWithBuffer = bn(estimatedGas)
        .times(100 + GAS_ESTIMATION_BUFFER_PERCENTAGE)
        .div(100)
      if (
        symbol === getNativeCurrencySymbol(chain) &&
        bn(balance).isLessThan(bn(estimatedGasWithBuffer).plus(value))
      ) {
        return t(
          "useValidateBalance.insufficientFundsForGas",
          "You don't have enough {{symbol}} to pay for gas",
          { symbol },
        )
      }

      return true
    },
    [balance, chain, estimatedGas, getNativeCurrencySymbol, symbol, t],
  )
}
type UseBalanceForGasErrorProps = {
  nativeBalance: NumberInput
  estimatedGas: NumberInput
  chain: ChainIdentifier
}

export const useBalanceForGasError = ({
  nativeBalance,
  estimatedGas,
  chain,
}: UseBalanceForGasErrorProps) => {
  const t = useTranslate("common")
  const { getNativeCurrencySymbol } = useChains()

  return useMemo(() => {
    const estimatedGasWithBuffer = bn(estimatedGas)
      .times(100 + GAS_ESTIMATION_BUFFER_PERCENTAGE)
      .div(100)
    if (bn(nativeBalance).isLessThan(estimatedGasWithBuffer)) {
      return t(
        "useValidateBalance.insufficientFundsForGas",
        "You don't have enough {{symbol}} to pay for gas",
        { symbol: getNativeCurrencySymbol(chain) },
      )
    }

    return undefined
  }, [chain, estimatedGas, getNativeCurrencySymbol, nativeBalance, t])
}
