import { useTranslate } from "@opensea/next-translate"
import { InfoFilled } from "@opensea/ui-kit/icons"
import React, { forwardRef, useMemo, useState } from "react"
import type { ChangeHandler } from "react-hook-form"
import { useUpdateEffect } from "react-use"
import { Button } from "@/design-system/Button"
import { FormControl } from "@/design-system/FormControl"
import { Input } from "@/design-system/Input"
import type { ChainIdentifier } from "@/hooks/useChains/types"
import { useNativeSymbol } from "@/hooks/useChains/useNativeSymbol"
import { isPolygon } from "@/lib/helpers/chainUtils"
import { calculateMaxWithGas } from "@/lib/helpers/gas"
import { bn } from "@/lib/helpers/numberUtils"

export type QuantityInputProps = {
  balance: string
  chain: ChainIdentifier
  symbol: string
  estimatedGas: string | null
  estimatingGas: boolean
  disabled?: boolean
  error?: string
  name: string
  operation: "Send" | "Wrap" | "Unwrap"
  value?: string
  onBlur?: ChangeHandler
  onChange?: ChangeHandler
  onValueChange?: (value: string) => void
  setError?: (message: string) => void
  required?: boolean
}

export const QuantityInput = forwardRef<HTMLInputElement, QuantityInputProps>(
  function QuantityInput(
    {
      balance,
      chain,
      symbol,
      estimatedGas,
      estimatingGas,
      disabled,
      error,
      name,
      operation,
      value,
      onBlur,
      onChange,
      onValueChange,
      setError,
      required,
    },
    ref,
  ) {
    const t = useTranslate("assets")
    const [isSetToMax, setIsSetToMax] = useState(false)
    const isNativeSymbol = useNativeSymbol(chain) === symbol

    const maxSendableBalance = calculateMaxWithGas(
      balance,
      !isNativeSymbol ? "0" : estimatedGas ?? "0",
    )

    const maxDisabled =
      maxSendableBalance.isLessThanOrEqualTo(0) ||
      bn(value || 0).isEqualTo(maxSendableBalance) ||
      estimatingGas

    const setMax = async () => {
      if (!isNativeSymbol) {
        setIsSetToMax(true)
        onValueChange?.(maxSendableBalance.toString())
        return
      }

      if (maxSendableBalance.isNaN()) {
        setError?.(
          t("quantityInput.unableToEstimateGas", "Unable to estimate gas"),
        )
        return
      }

      if (maxSendableBalance.isLessThanOrEqualTo(0)) {
        setError?.(
          t(
            "quantityInput.insufficientBalance",
            "Insufficient balance to cover gas",
          ),
        )
        return
      }

      setIsSetToMax(true)
      onValueChange?.(maxSendableBalance.toString())
    }

    useUpdateEffect(() => {
      if (value && !maxSendableBalance.isEqualTo(value)) {
        setIsSetToMax(false)
      }
    }, [maxSendableBalance, value])

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setIsSetToMax(false)
      onChange?.(event)
      onValueChange?.(event.target.value)
    }

    const showMaxInfo = useMemo(() => {
      if (operation !== "Send" && isPolygon(chain)) {
        return false
      }

      return isSetToMax && isNativeSymbol
    }, [chain, isNativeSymbol, isSetToMax, operation])

    return (
      <>
        <FormControl
          error={error}
          hideLabel
          htmlFor="amount"
          label={t("quantityInput.amount", "Amount")}
          warning={error}
        >
          <Input
            autoComplete="off"
            disabled={disabled}
            endEnhancer={
              <div className="flex h-full items-center space-x-2">
                <span className="text-primary">{symbol.toUpperCase()}</span>
                <Button
                  className="relative h-7 px-3 py-1 text-sm font-normal text-primary"
                  disabled={maxDisabled}
                  onClick={setMax}
                  variant="secondary"
                >
                  {t("quantityInput.max", "Max")}
                </Button>
              </div>
            }
            error={Boolean(error)}
            id="amount"
            name={name}
            onBlur={onBlur}
            onChange={handleChange}
            placeholder={t("quantityInput.amount", "Amount")}
            ref={ref}
            required={required}
            value={value}
          />
        </FormControl>
        {showMaxInfo ? (
          <div className="!mt-2 flex items-center space-x-1 text-sm leading-[20px] text-secondary">
            <InfoFilled size={16} />
            <span>
              {t(
                "quantityInput.maxExplanation",
                "Max balance minus estimated gas needed.",
              )}
            </span>
          </div>
        ) : null}
      </>
    )
  },
)
