import { Dialog, Transition } from "@headlessui/react"
import React, { useEffect, useState } from "react"
import { ModalBody } from "./components/ModalBody.react"
import { ModalHeader } from "./components/ModalHeader.react"

export type ModalProps = {
  isOpen: boolean
  onClose: () => void
  onAfterLeave?: () => void
  children: React.ReactNode
}

function ModalBase(props: ModalProps) {
  const { isOpen, onClose, onAfterLeave, children } = props

  const [isChildrenVisible, setIsChildrenVisible] = useState(false)

  useEffect(() => {
    if (isOpen) {
      /**
       * This is a way to ensure that the children are visible while the modal is
       * transitioning out. This is necessary to avoid a flash of content when
       * the modal is closed.
       */
      setIsChildrenVisible(true)
    }
  }, [isOpen])

  const onAnimationFinished = () => {
    setIsChildrenVisible(false)
    onAfterLeave?.()
  }

  return (
    <Dialog
      as="div"
      className="fixed inset-0 z-10"
      onClose={onClose}
      open={isOpen || isChildrenVisible}
    >
      <Transition
        afterLeave={onAnimationFinished}
        appear
        enter="transition ease-in-out duration-[350ms] transform"
        enterFrom="translate-y-full bg-red-500"
        enterTo="translate-y-0"
        leave="transition ease-in-out duration-[350ms] transform"
        leaveFrom="translate-y-0"
        leaveTo="translate-y-full"
        show={isOpen}
      >
        <div className="flex h-screen w-screen flex-col bg-elevation-2">
          <Transition
            as="div"
            className="flex flex-1 flex-col overflow-hidden"
            enter="transition ease-in-out duration-200 delay-100"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition ease-in-out duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            show={isOpen}
          >
            {/* We do the OR here to ensure children will mount as soon as isOpen becomes true */}
            {isOpen || isChildrenVisible ? children : null}
          </Transition>
        </div>
      </Transition>
    </Dialog>
  )
}

export const Modal = Object.assign(ModalBase, {
  Body: ModalBody,
  Header: ModalHeader,
})
