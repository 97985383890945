import { useTranslate } from "@opensea/next-translate"
import Image from "next/image"
import React from "react"
import { useForm } from "react-hook-form"
import type { PreloadedQuery } from "react-relay"
import { graphql, useFragment, usePreloadedQuery } from "react-relay"
import { Button } from "@/design-system/Button"
import { Form } from "@/design-system/Form"
import { FormControl } from "@/design-system/FormControl"
import type { ChainIdentifier } from "@/hooks/useChains/types"
import type { SendTokenModalQuery } from "@/lib/graphql/__generated__/SendTokenModalQuery.graphql"
import type { TransactionDetailsFragment$key } from "@/lib/graphql/__generated__/TransactionDetailsFragment.graphql"
import { getEquallySizedChainLogo } from "@/lib/helpers/chainUtils"
import { useIsValidAmount } from "../../hooks/useIsValidAmount"
import {
  useBalanceForGasError,
  useValidateBalance,
} from "../../hooks/useValidateBalance"
import { QuantityInput } from "../QuantityInput"
import { AddressInput } from "./AddressInput.react"
import { SEND_TOKEN_QUERY } from "./SendTokenModalQuery"

type TransactionDetailsProps = {
  estimatedGas: string | null
  estimatingGas: boolean
  onSubmit: (data: { address: string; quantity: string }) => void
  chain: ChainIdentifier
  symbol: string
  queryRef: PreloadedQuery<SendTokenModalQuery>
  initialValues?: {
    address: string
    quantity: string
  }
}

export function TransactionDetails(props: TransactionDetailsProps) {
  const { queryRef, ...rest } = props
  const data = usePreloadedQuery(SEND_TOKEN_QUERY, queryRef)

  const {
    wallet: {
      fundsOf: { balance, assetImage },
      nativeFunds: { quantity: nativeBalance },
    },
    paymentAsset: { decimals },
  } = useFragment<TransactionDetailsFragment$key>(
    graphql`
      fragment TransactionDetailsFragment on Query
      @argumentDefinitions(
        address: { type: "AddressScalar!" }
        chain: { type: "ChainScalar!" }
        symbol: { type: "String!" }
        nativeSymbol: { type: "String!" }
      ) {
        wallet(address: $address) {
          fundsOf(symbol: $symbol, chain: $chain) {
            balance: quantity
            assetImage: image
          }
          nativeFunds: fundsOf(symbol: $nativeSymbol, chain: $chain) {
            quantity
          }
        }

        paymentAsset(symbol: $symbol, chain: $chain) {
          decimals
        }
      }
    `,
    data,
  )

  return (
    <TransactionDetailsBase
      {...rest}
      assetImage={assetImage}
      balance={balance}
      decimals={decimals}
      nativeBalance={nativeBalance}
    />
  )
}

export function TransactionDetailsBase({
  assetImage,
  balance,
  nativeBalance,
  chain,
  decimals,
  initialValues,
  estimatedGas,
  estimatingGas,
  onSubmit,
  symbol,
}: Omit<TransactionDetailsProps, "queryRef"> & {
  assetImage: string | null | undefined
  balance: string
  nativeBalance: string
  decimals: number
}) {
  const t = useTranslate("assets")
  const ChainLogo = getEquallySizedChainLogo(chain)

  const form = useForm({
    mode: "onChange",
    defaultValues: initialValues ?? {
      quantity: "",
    },
  })

  const {
    formState: { isSubmitting, errors },
  } = form

  const validateIsNumericInput = useIsValidAmount(decimals)
  const validateBalance = useValidateBalance({
    balance,
    estimatedGas: estimatedGas ?? 0,
    chain,
    symbol,
  })
  const balanceForGasError = useBalanceForGasError({
    nativeBalance,
    estimatedGas: estimatedGas ?? 0,
    chain,
  })

  const { address, quantity } = form.watch()

  return (
    <Form
      className="flex flex-1 flex-col justify-between"
      onSubmit={form.handleSubmit(values => {
        onSubmit(values)
      })}
    >
      <div className="flex flex-col space-y-4 p-4">
        <div className="mb-6 mt-4 flex items-center justify-center">
          <div className="flex size-[100px] items-center justify-center rounded-large bg-component-gray-1">
            {assetImage ? (
              <Image alt="" height={50} src={assetImage} width={50} />
            ) : null}
            {!assetImage && ChainLogo ? <ChainLogo width={32} /> : null}
          </div>
        </div>

        <FormControl
          error={errors.address?.message}
          hideLabel
          htmlFor="address"
          label={t(
            "transactionDetailsBase.addressInput.label",
            "Recipient's wallet address",
          )}
        >
          <AddressInput
            address={address}
            chain={chain}
            id="address"
            onAddressChange={newAddress => {
              form.setValue("address", newAddress, {
                shouldValidate: true,
              })
            }}
            setError={message => {
              message
                ? form.setError("address", {
                    type: "value",
                    message,
                  })
                : form.clearErrors("address")
            }}
          />
        </FormControl>

        <QuantityInput
          balance={balance}
          chain={chain}
          error={errors.quantity?.message ?? balanceForGasError}
          estimatedGas={estimatedGas}
          estimatingGas={estimatingGas}
          onValueChange={value => {
            form.setValue("quantity", value, {
              shouldValidate: true,
            })
          }}
          operation="Send"
          setError={message => {
            form.setError("quantity", { message })
          }}
          symbol={symbol}
          value={quantity}
          {...form.register("quantity", {
            required: t(
              "transactionDetailsBase.errors.quantityRequired",
              "Please enter a valid amount.",
            ),
            validate: {
              validateIsNumericInput,
              validateBalance,
            },
          })}
        />
      </div>
      <Button
        className="w-full justify-center"
        disabled={
          !address ||
          Boolean(errors.address) ||
          Boolean(errors.quantity) ||
          estimatingGas
        }
        isLoading={isSubmitting}
        type="submit"
      >
        {t("transactionDetailsBase.next", "Next")}
      </Button>
    </Form>
  )
}
