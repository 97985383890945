import { useEffect } from "react"
import { useQueryLoader } from "react-relay"
import type { GraphQLTaggedNode, OperationType } from "relay-runtime"

export function useAutoLoadQueryLoader<TQuery extends OperationType>(
  query: GraphQLTaggedNode,
  variables?: TQuery["variables"] | null | false,
) {
  const [queryReference, loadQuery, disposeQuery] =
    useQueryLoader<TQuery>(query)

  useEffect(() => {
    if (variables) {
      loadQuery(variables)
    }

    return disposeQuery
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disposeQuery, loadQuery, JSON.stringify(variables)])

  return queryReference
}
