import React from "react"

type Props = {
  height?: number
  width?: number
}

export function EmptyWalletIcon({ width = 80, height = 80 }: Props) {
  return (
    <svg
      fill="none"
      height={height}
      viewBox="0 0 100 100"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7 28C7 23.5817 10.5817 20 15 20H73C77.4183 20 81 23.5817 81 28V76C81 80.4183 77.4183 84 73 84H15C10.5817 84 7 80.4183 7 76V28Z"
        fill="url(#paint0_linear_6146_5380)"
      />
      <circle cx="50" cy="28" fill="#F6C000" r="16" />
      <path
        clipRule="evenodd"
        d="M7 36V28C7 32.4183 10.5817 36 15 36H85C89.4183 36 93 39.5817 93 44V92C93 96.4183 89.4183 100 85 100H15C10.5817 100 7 96.4183 7 92V36Z"
        fill="url(#paint1_linear_6146_5380)"
        fillRule="evenodd"
      />
      <circle cx="81" cy="68" fill="white" r="4" />
      <rect
        fill="#F6C000"
        height="10"
        transform="rotate(45 14.071 0)"
        width="10"
        x="14.071"
      />
      <rect
        fill="white"
        height="10"
        transform="rotate(45 50.071 21)"
        width="10"
        x="50.071"
        y="21"
      />
      <rect
        fill="#F6C000"
        height="4"
        transform="rotate(45 86.8284 4)"
        width="4"
        x="86.8284"
        y="4"
      />
      <defs>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint0_linear_6146_5380"
          x1="44"
          x2="44"
          y1="20"
          y2="52"
        >
          <stop stopColor="#2081E2" />
          <stop offset="1" stopColor="#003D7A" />
        </linearGradient>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint1_linear_6146_5380"
          x1="50"
          x2="50"
          y1="28"
          y2="100"
        >
          <stop stopColor="#54CCFF" />
          <stop offset="1" stopColor="#42A0FF" />
        </linearGradient>
      </defs>
    </svg>
  )
}
