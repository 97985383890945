import { useTranslate } from "@opensea/next-translate"
import { classNames, IconButton } from "@opensea/ui-kit"
import { ChevronLeft, Close } from "@opensea/ui-kit/icons"
import React from "react"
import { Text } from "@/design-system/Text"

export function ModalHeader({
  title,
  steps,
  currentStep,
  onClose,
  onBack,
}: {
  title: string
  steps?: number
  currentStep?: number
  onClose?: () => void
  onBack?: () => void
}) {
  const t = useTranslate("common")
  return (
    <div className="relative flex h-18 items-center justify-center border-b border-b-level-1 p-6">
      {onBack ? (
        <IconButton
          aria-label={t("modalHeader.back", "Back")}
          className="absolute left-3 top-3"
          onClick={onBack}
        >
          <ChevronLeft />
        </IconButton>
      ) : null}

      <Text.Heading role="heading" size="small">
        {title}
      </Text.Heading>

      {onClose ? (
        <IconButton
          aria-label={t("modalHeader.close", "Close")}
          className="absolute right-3 top-3"
          onClick={onClose}
        >
          <Close />
        </IconButton>
      ) : null}

      {steps ? (
        <div className="absolute inset-x-0 bottom-0 flex h-0.5 space-x-1">
          {Array.from({ length: steps }).map((_, index) => (
            <div
              className={classNames(
                "h-full flex-1",
                index <= (currentStep ?? 0)
                  ? "bg-gray-3 dark:bg-white"
                  : "bg-component-gray-3",
              )}
              key={index}
            />
          ))}
        </div>
      ) : null}
    </div>
  )
}
