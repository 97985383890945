import { useTranslate } from "@opensea/next-translate"
import React, { useState, useEffect, useCallback } from "react"
import { captureException } from "@sentry/nextjs"
import { Modal } from "@/design-system/Modal/Modal.react"
import { useAutoLoadQueryLoader } from "@/hooks/useAutoLoadQueryLoader"
import { useNativeSymbol } from "@/hooks/useChains/useNativeSymbol"
import { useGasEstimate } from "@/hooks/useGasEstimate"
import type { SendTokenModalQuery } from "@/lib/graphql/__generated__/SendTokenModalQuery.graphql"
import { useConnectedAddress } from "@/providers/Wallet/selectors"
import { useWalletActions } from "@/providers/Wallet/useWalletActions"
import { useVessel } from "@/providers/Vessel00/VesselProvider.react"
import { useRefreshWalletBalances } from "@/providers/WalletBalanceProvider"
import type { Asset } from "../Assets/AssetProvider.react"
import {
  useAsset,
  useExitAssetAction,
  useAssetAction,
  useSetAsset,
} from "../Assets/hooks"
import { ConfirmTransaction } from "./ConfirmTransaction.react"
import { TransactionDetails } from "./TransactionDetails.react"
import { SEND_TOKEN_QUERY } from "./SendTokenModalQuery"

export function SendTokenModal() {
  const t = useTranslate("assets")

  const asset = useAsset()
  const setAsset = useSetAsset()
  const assetAction = useAssetAction()
  const exitAssetAction = useExitAssetAction()

  const [step, setStep] = useState(0)

  const showConfirmationStep = () => {
    setStep(1)
  }
  const showDetailsStep = () => {
    setStep(0)
  }

  let title: string
  if (step === 0) {
    if (asset?.symbol) {
      title = t("sendTokenModal.title.sendSymbol", "Send {{symbol}}", {
        symbol: asset.symbol,
      })
    } else {
      title = t("sendTokenModal.title.send", "Send")
    }
  } else {
    title = t("sendTokenModal.title.approve", "Approve transfer")
  }

  return (
    <Modal
      isOpen={assetAction === "SEND"}
      onAfterLeave={() => {
        setAsset(null)
      }}
      onClose={exitAssetAction}
    >
      <Modal.Header
        currentStep={step}
        onBack={step > 0 ? showDetailsStep : undefined}
        onClose={exitAssetAction}
        steps={2}
        title={title}
      />
      <Modal.Body className="flex flex-1 flex-col p-4">
        {asset ? (
          <SendTokenModalBody
            asset={asset}
            currentStep={step}
            showConfirmationStep={showConfirmationStep}
            showDetailsStep={showDetailsStep}
          />
        ) : null}
      </Modal.Body>
    </Modal>
  )
}

function SendTokenModalBody({
  asset,
  currentStep,
  showDetailsStep,
  showConfirmationStep,
}: {
  asset: Asset
  currentStep: number
  showDetailsStep: () => void
  showConfirmationStep: () => void
}) {
  const address = useConnectedAddress()
  const exitAssetAction = useExitAssetAction()
  const refreshWalletBalances = useRefreshWalletBalances()
  const nativeSymbol = useNativeSymbol(asset.chain)
  const { emitError } = useVessel()
  const t = useTranslate("assets")

  const queryRef = useAutoLoadQueryLoader<SendTokenModalQuery>(
    SEND_TOKEN_QUERY,
    address
      ? { address, chain: asset.chain, symbol: asset.symbol, nativeSymbol }
      : null,
  )

  const [recipientAddress, setRecipientAddress] = useState("")
  const [quantity, setQuantity] = useState("")
  const { estimatingGas, gasEstimate } = useGasEstimate({
    action: "Transfer",
    chain: asset.chain,
    symbol: asset.symbol,
  })

  const resetState = useCallback(() => {
    setRecipientAddress("")
    setQuantity("")
  }, [])

  useEffect(() => {
    if ((!quantity || !recipientAddress) && currentStep === 1) {
      showDetailsStep()
    }
  }, [quantity, recipientAddress, currentStep, showDetailsStep])

  const { transfer } = useWalletActions()

  const handleTransactionDetails = useCallback(
    (details: { quantity: string; address: string }) => {
      setRecipientAddress(details.address)
      setQuantity(details.quantity)
      showConfirmationStep()
    },
    [showConfirmationStep],
  )

  const handleConfirmTransaction = async () => {
    if (!quantity || !recipientAddress || !gasEstimate) {
      emitError(
        t("sendTokenModal.error.invalidState", "Invalid transfer state"),
      )
      return
    }

    try {
      await transfer({
        chain: asset.chain,
        symbol: asset.symbol,
        quantity,
        toAddress: recipientAddress,
      })

      resetState()
      exitAssetAction()
      await refreshWalletBalances()
    } catch (error) {
      captureException(error, {
        extra: {
          action: "transfer",
          symbol: asset.symbol,
          quantity,
          recipientAddress,
        },
      })
      emitError((error as Error).message)
    }
  }

  if (!queryRef) {
    // TODO: Loading state - should we do skeletons?
    return null
  }

  if (currentStep === 0) {
    return (
      <TransactionDetails
        chain={asset.chain}
        estimatedGas={gasEstimate}
        estimatingGas={estimatingGas}
        initialValues={{
          address: recipientAddress,
          quantity,
        }}
        onSubmit={handleTransactionDetails}
        queryRef={queryRef}
        symbol={asset.symbol}
      />
    )
  }

  return (
    <ConfirmTransaction
      chain={asset.chain}
      estimatedGas={gasEstimate}
      estimatingGas={estimatingGas}
      onConfirm={handleConfirmTransaction}
      quantity={quantity}
      queryRef={queryRef}
      symbol={asset.symbol}
    />
  )
}
