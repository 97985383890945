/**
 * @generated SignedSource<<4b140e8064da9d1d4c83277404273c63>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type WrapTransactionDetailsFragment$data = {
  readonly basePaymentAsset: {
    readonly decimals: number;
  };
  readonly wallet: {
    readonly baseFund: {
      readonly balance: string;
      readonly symbol: string;
    };
    readonly wrappedFund: {
      readonly balance: string;
      readonly symbol: string;
    };
  };
  readonly wrappedPaymentAsset: {
    readonly decimals: number;
  };
  readonly " $fragmentType": "WrapTransactionDetailsFragment";
};
export type WrapTransactionDetailsFragment$key = {
  readonly " $data"?: WrapTransactionDetailsFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"WrapTransactionDetailsFragment">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "kind": "Variable",
    "name": "chain",
    "variableName": "baseChain"
  },
  {
    "kind": "Variable",
    "name": "symbol",
    "variableName": "baseSymbol"
  }
],
v1 = [
  {
    "alias": "balance",
    "args": null,
    "kind": "ScalarField",
    "name": "quantity",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "symbol",
    "storageKey": null
  }
],
v2 = [
  {
    "kind": "Variable",
    "name": "chain",
    "variableName": "wrappedChain"
  },
  {
    "kind": "Variable",
    "name": "symbol",
    "variableName": "wrappedSymbol"
  }
],
v3 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "decimals",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "address"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "baseChain"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "baseSymbol"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "wrappedChain"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "wrappedSymbol"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "WrapTransactionDetailsFragment",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "address",
          "variableName": "address"
        }
      ],
      "concreteType": "WalletType",
      "kind": "LinkedField",
      "name": "wallet",
      "plural": false,
      "selections": [
        {
          "alias": "baseFund",
          "args": (v0/*: any*/),
          "concreteType": "WalletFundsType",
          "kind": "LinkedField",
          "name": "fundsOf",
          "plural": false,
          "selections": (v1/*: any*/),
          "storageKey": null
        },
        {
          "alias": "wrappedFund",
          "args": (v2/*: any*/),
          "concreteType": "WalletFundsType",
          "kind": "LinkedField",
          "name": "fundsOf",
          "plural": false,
          "selections": (v1/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": "basePaymentAsset",
      "args": (v0/*: any*/),
      "concreteType": "PaymentAssetType",
      "kind": "LinkedField",
      "name": "paymentAsset",
      "plural": false,
      "selections": (v3/*: any*/),
      "storageKey": null
    },
    {
      "alias": "wrappedPaymentAsset",
      "args": (v2/*: any*/),
      "concreteType": "PaymentAssetType",
      "kind": "LinkedField",
      "name": "paymentAsset",
      "plural": false,
      "selections": (v3/*: any*/),
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "c3c430b9d87f3a9ec9e88502e6132b80";

export default node;
