import { classNames } from "@opensea/ui-kit"
import React from "react"

export type LabelProps = React.DetailedHTMLProps<
  React.LabelHTMLAttributes<HTMLLabelElement>,
  HTMLLabelElement
> & {
  required?: boolean
  visuallyHidden?: boolean
}

export function Label({
  required,
  visuallyHidden,
  children,
  ...rest
}: LabelProps) {
  return (
    <label
      {...rest}
      className={classNames(
        "font-inter text-md font-semibold text-primary",
        {
          // Cannot just set display: none or visibility: hidden since that might
          // completely hide the label from screen readers. Based on:
          // https://www.w3.org/WAI/tutorials/forms/labels/#note-on-hiding-elements
          "sr-only": visuallyHidden,
          'after:content-["_*"]': required,
        },
        rest.className,
      )}
    >
      {children}
    </label>
  )
}
