import { useTranslate } from "@opensea/next-translate"
import { classNames, Skeleton, twDataIdSelector } from "@opensea/ui-kit"
import { Tooltip } from "@opensea/ui-kit/client"
import { Add, ArrowDownward } from "@opensea/ui-kit/icons"
import React from "react"
import { Button } from "@/design-system/Button"
import { Dropdown } from "@/design-system/Dropdown"
import { Text } from "@/design-system/Text"
import { displayFiat } from "@/lib/helpers/numberUtils"
import {
  useAddFunds,
  useConnectedChain,
  useDepositFunds,
} from "@/providers/Wallet/selectors"
import { useTotalWalletValueUsd } from "@/providers/WalletBalanceProvider"

type Option = {
  value: "addFunds" | "depositFunds"
  label: string
  icon: React.ReactNode
  action: () => void
}

export function WalletBalance() {
  const addFunds = useAddFunds()
  const depositFunds = useDepositFunds()
  const chain = useConnectedChain()
  const { value: totalWalletValueUsd, ready: totalWalletValueUsdReady } =
    useTotalWalletValueUsd(chain)
  const t = useTranslate("common")

  const options: Option[] = [
    {
      value: "addFunds",
      label: t("walletBalance.buyCrypto", "Buy"),
      icon: <Add />,
      action: addFunds,
    },
    {
      value: "depositFunds",
      label: t("walletBalance.depositCrypto", "Deposit"),
      icon: <ArrowDownward />,
      action: depositFunds,
    },
  ]

  return (
    <div className="flex justify-between px-6 pb-5 pt-2 fade-in">
      <div>
        {totalWalletValueUsdReady ? (
          <div className="text-heading-md font-semibold">
            {displayFiat(totalWalletValueUsd)} USD
          </div>
        ) : (
          <Skeleton className="h-auto">
            <Skeleton.Row className="py-1">
              <Skeleton.Line className="h-7 w-[120px]" />
            </Skeleton.Row>
          </Skeleton>
        )}
        <div className="text-secondary">
          {t("walletBalance.title", "Wallet balance")}
        </div>
      </div>

      <Tooltip
        align="end"
        content={t("walletBalance.addFunds", "Add funds")}
        delayDuration={300}
      >
        <div>
          <Dropdown
            appendTo={document.body}
            className="min-w-[160px]"
            content={({ List, Item, close }) => (
              <List
                className={classNames(
                  twDataIdSelector(Item, "[&_[data-id=Item]]:rounded-lg"),
                  "overflow-auto bg-transparent p-3",
                )}
                showBorder={false}
              >
                {options.map(({ value, label, icon, action }) => (
                  <Item
                    interactive
                    key={value}
                    onClick={() => {
                      action()
                      close()
                    }}
                  >
                    <div className="flex items-center gap-x-3">
                      {icon}
                      <Text weight="semibold">{label}</Text>
                    </div>
                  </Item>
                ))}
              </List>
            )}
          >
            <Button
              aria-label={t("walletBalance.addFunds", "Add funds")}
              className="size-14"
              icon={<Add />}
              variant="secondary"
            />
          </Dropdown>
        </div>
      </Tooltip>
    </div>
  )
}
