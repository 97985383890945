import { MoreHoriz } from "@opensea/ui-kit/icons"
import React from "react"
import type { RenderItem } from "@/design-system/Dropdown"
import { Dropdown } from "@/design-system/Dropdown"
import { Overflow } from "@/design-system/Overflow"
import { Text } from "@/design-system/Text"

export type ItemAction = {
  icon: React.ReactNode
  label: string
  onClick: () => void
}

type MobileItemActionsProps = {
  actions: ItemAction[]
  className?: string
}

export function MobileItemActions(props: MobileItemActionsProps) {
  const { actions, className } = props

  const renderOption: RenderItem<ItemAction> = props => {
    const { Item, item, close } = props

    const handleClick = () => {
      item.onClick()
      close()
    }

    return (
      <Item key={item.label} onClick={handleClick}>
        <Overflow className="flex w-full items-center justify-between">
          <div className="flex items-center gap-x-3">
            {item.icon}
            <Text weight="semibold">{item.label}</Text>
          </div>
        </Overflow>
      </Item>
    )
  }

  return (
    <Dropdown
      animation="shift-away"
      className="min-w-[126px]"
      content={({ List, Item, close }) => (
        <List>
          {actions.map(action => renderOption({ Item, item: action, close }))}
        </List>
      )}
      placement="top-start"
    >
      <div className={className}>
        <MoreHoriz className="rotate-90" />
      </div>
    </Dropdown>
  )
}
