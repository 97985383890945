import * as ethereumjsUtils from "ethereumjs-util"
import * as solanaUtils from "./solana"

export const isValidSolanaAddress = solanaUtils.isValidAddress

export const isValidEvmAddress = (value: string) => {
  return ethereumjsUtils.isValidAddress(value)
}

export const isValidAddress = (value: string) => {
  return isValidEvmAddress(value) || isValidSolanaAddress(value)
}
