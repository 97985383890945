import { useMemo } from "react"
import { useChains } from "@/hooks/useChains"
import { getEthereumChain, isPolygon } from "@/lib/helpers/chainUtils"
import type { Asset } from "./AssetProvider.react"

export const useAssetSymbols = (asset: Asset) => {
  const { getNativeCurrencySymbol, getWrappedCurrencySymbol } = useChains()

  const { chain } = asset

  return useMemo(() => {
    if (isPolygon(chain)) {
      return {
        // Bridging from ETH on Ethereum to ETH on Polygon
        baseSymbol: "ETH",
        baseChain: getEthereumChain(),
        wrappedSymbol: "ETH",
        wrappedChain: chain,
      }
    }

    return {
      baseSymbol: getNativeCurrencySymbol(chain),
      baseChain: chain,
      wrappedSymbol: getWrappedCurrencySymbol(chain),
      wrappedChain: chain,
    }
  }, [chain, getNativeCurrencySymbol, getWrappedCurrencySymbol])
}
