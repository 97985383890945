/**
 * @generated SignedSource<<5fb0c257c838dd44cbcda2db2f5cc65b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ConfirmWrapTransactionFragment$data = {
  readonly basePaymentAsset: {
    readonly usdPrice: string;
  };
  readonly wrappedPaymentAsset: {
    readonly usdPrice: string;
  };
  readonly " $fragmentType": "ConfirmWrapTransactionFragment";
};
export type ConfirmWrapTransactionFragment$key = {
  readonly " $data"?: ConfirmWrapTransactionFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ConfirmWrapTransactionFragment">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "usdPrice",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "baseChain"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "baseSymbol"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "wrappedChain"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "wrappedSymbol"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "ConfirmWrapTransactionFragment",
  "selections": [
    {
      "alias": "basePaymentAsset",
      "args": [
        {
          "kind": "Variable",
          "name": "chain",
          "variableName": "baseChain"
        },
        {
          "kind": "Variable",
          "name": "symbol",
          "variableName": "baseSymbol"
        }
      ],
      "concreteType": "PaymentAssetType",
      "kind": "LinkedField",
      "name": "paymentAsset",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": "wrappedPaymentAsset",
      "args": [
        {
          "kind": "Variable",
          "name": "chain",
          "variableName": "wrappedChain"
        },
        {
          "kind": "Variable",
          "name": "symbol",
          "variableName": "wrappedSymbol"
        }
      ],
      "concreteType": "PaymentAssetType",
      "kind": "LinkedField",
      "name": "paymentAsset",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "070e3de5177482b31cd6b9ed8604249b";

export default node;
