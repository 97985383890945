import { RadioGroup } from "@headlessui/react"
import { useTranslate } from "@opensea/next-translate"
import { classNames } from "@opensea/ui-kit"
import React from "react"

export type DirectionValueType = "Wrap" | "Unwrap"

type WrapUnwrapRadioButtonProps = {
  value: DirectionValueType
  onChange: (value: DirectionValueType) => void
  unwrapDisabled?: boolean
  unwrapLabel?: string
  onUnwrapClick?: () => void
  wrapDisabled?: boolean
  wrapLabel?: string
  onWrapClick?: () => void
}

export function WrapUnwrapRadioButton(props: WrapUnwrapRadioButtonProps) {
  const {
    value,
    onChange,
    wrapLabel,
    unwrapLabel,
    wrapDisabled,
    unwrapDisabled,
    onWrapClick,
    onUnwrapClick,
  } = props
  const t = useTranslate("common")

  const itemClassName =
    "relative z-10 flex h-full flex-1 items-center justify-center"

  return (
    <RadioGroup
      className="relative flex h-12 items-center overflow-hidden rounded-large bg-component-gray-1 p-0.5 dark:bg-elevation-1"
      onChange={onChange}
      value={value}
    >
      <RadioGroup.Label className="sr-only">
        {t("toggleDirection", "Toggle direction")}
      </RadioGroup.Label>
      <span className={itemClassName} onClick={onWrapClick}>
        <RadioGroup.Option
          as="button"
          className="size-full rounded-large"
          disabled={wrapDisabled}
          type="button"
          value="Wrap"
        >
          <RadioGroup.Label className="cursor-pointer font-semibold">
            {wrapLabel ?? "Wrap"}
          </RadioGroup.Label>
        </RadioGroup.Option>
      </span>
      <span className={itemClassName} onClick={onUnwrapClick}>
        <RadioGroup.Option
          as="button"
          className="size-full rounded-large"
          disabled={unwrapDisabled}
          type="button"
          value="Unwrap"
        >
          <RadioGroup.Label className="cursor-pointer font-semibold">
            {unwrapLabel ?? "Unwrap"}
          </RadioGroup.Label>
        </RadioGroup.Option>
      </span>
      <div
        className={classNames(
          "absolute inset-y-0.5 z-0 flex w-1/2 items-center justify-center rounded-large bg-white shadow-[0_0_10px_0_#00000010] transition-all duration-200 dark:bg-component-gray-2",
          {
            "left-0.5": value === "Wrap",
            "left-[calc(50%-2px)]": value === "Unwrap",
          },
        )}
      />
    </RadioGroup>
  )
}
