import { graphql } from "relay-runtime"

export const WRAP_TOKEN_QUERY = graphql`
  query WrapTokenModalQuery(
    $address: AddressScalar!
    $baseSymbol: String!
    $baseChain: ChainScalar!
    $wrappedSymbol: String!
    $wrappedChain: ChainScalar!
  ) {
    ...ConfirmWrapTransactionFragment
      @arguments(
        baseSymbol: $baseSymbol
        baseChain: $baseChain
        wrappedSymbol: $wrappedSymbol
        wrappedChain: $wrappedChain
      )

    ...WrapTransactionDetailsFragment
      @arguments(
        address: $address
        baseSymbol: $baseSymbol
        baseChain: $baseChain
        wrappedSymbol: $wrappedSymbol
        wrappedChain: $wrappedChain
      )
  }
`
