import { useEffect } from "react"
import { useTrackingFn } from "@/lib/analytics/useTrackingFn"
import { useIsWalletVisible } from "@/providers/Wallet/selectors"

type PageViewTrackerProps = {
  name: string
  data?: Record<string, unknown> | null
}

export function PageViewTracker({ name, data }: PageViewTrackerProps) {
  const isWalletVisible = useIsWalletVisible()
  const trackPageView = useTrackingFn("Page View")

  useEffect(() => {
    if (data !== null && isWalletVisible) {
      trackPageView()
    }
  }, [name, trackPageView, data, isWalletVisible])

  return null
}
