/**
 * @generated SignedSource<<5246b4bae92db602f263535b9d8e06a8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type WrapTokenModalQuery$variables = {
  address: string;
  baseChain: | "ETHEREUM" | "MATIC" | "KLAYTN" | "BSC" | "SOLANA" | "ARBITRUM" | "ARBITRUM_NOVA" | "AVALANCHE" | "OPTIMISM" | "OPTIMISM_SEPOLIA" | "AMOY" | "BAOBAB" | "BSC_TESTNET" | "SOLDEV" | "ARBITRUM_SEPOLIA" | "AVALANCHE_FUJI" | "SEPOLIA" | "BASE" | "BASE_SEPOLIA" | "ZORA" | "ZORA_SEPOLIA" | "ZORA_TESTNET" | "BLAST_SEPOLIA" | "BLAST" | "GUNZILLA_TESTNET" | "SEI" | "SEI_TESTNET" | "%future added value";
  baseSymbol: string;
  wrappedChain: | "ETHEREUM" | "MATIC" | "KLAYTN" | "BSC" | "SOLANA" | "ARBITRUM" | "ARBITRUM_NOVA" | "AVALANCHE" | "OPTIMISM" | "OPTIMISM_SEPOLIA" | "AMOY" | "BAOBAB" | "BSC_TESTNET" | "SOLDEV" | "ARBITRUM_SEPOLIA" | "AVALANCHE_FUJI" | "SEPOLIA" | "BASE" | "BASE_SEPOLIA" | "ZORA" | "ZORA_SEPOLIA" | "ZORA_TESTNET" | "BLAST_SEPOLIA" | "BLAST" | "GUNZILLA_TESTNET" | "SEI" | "SEI_TESTNET" | "%future added value";
  wrappedSymbol: string;
};
export type WrapTokenModalQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ConfirmWrapTransactionFragment" | "WrapTransactionDetailsFragment">;
};
export type WrapTokenModalQuery = {
  response: WrapTokenModalQuery$data;
  variables: WrapTokenModalQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "address"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "baseChain"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "baseSymbol"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "wrappedChain"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "wrappedSymbol"
},
v5 = {
  "kind": "Variable",
  "name": "baseChain",
  "variableName": "baseChain"
},
v6 = {
  "kind": "Variable",
  "name": "baseSymbol",
  "variableName": "baseSymbol"
},
v7 = {
  "kind": "Variable",
  "name": "wrappedChain",
  "variableName": "wrappedChain"
},
v8 = {
  "kind": "Variable",
  "name": "wrappedSymbol",
  "variableName": "wrappedSymbol"
},
v9 = {
  "kind": "Variable",
  "name": "address",
  "variableName": "address"
},
v10 = [
  {
    "kind": "Variable",
    "name": "chain",
    "variableName": "baseChain"
  },
  {
    "kind": "Variable",
    "name": "symbol",
    "variableName": "baseSymbol"
  }
],
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v12 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "usdPrice",
    "storageKey": null
  },
  (v11/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "decimals",
    "storageKey": null
  }
],
v13 = [
  {
    "kind": "Variable",
    "name": "chain",
    "variableName": "wrappedChain"
  },
  {
    "kind": "Variable",
    "name": "symbol",
    "variableName": "wrappedSymbol"
  }
],
v14 = [
  {
    "alias": "balance",
    "args": null,
    "kind": "ScalarField",
    "name": "quantity",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "symbol",
    "storageKey": null
  },
  (v11/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "WrapTokenModalQuery",
    "selections": [
      {
        "args": [
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/)
        ],
        "kind": "FragmentSpread",
        "name": "ConfirmWrapTransactionFragment"
      },
      {
        "args": [
          (v9/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/)
        ],
        "kind": "FragmentSpread",
        "name": "WrapTransactionDetailsFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/),
      (v4/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Operation",
    "name": "WrapTokenModalQuery",
    "selections": [
      {
        "alias": "basePaymentAsset",
        "args": (v10/*: any*/),
        "concreteType": "PaymentAssetType",
        "kind": "LinkedField",
        "name": "paymentAsset",
        "plural": false,
        "selections": (v12/*: any*/),
        "storageKey": null
      },
      {
        "alias": "wrappedPaymentAsset",
        "args": (v13/*: any*/),
        "concreteType": "PaymentAssetType",
        "kind": "LinkedField",
        "name": "paymentAsset",
        "plural": false,
        "selections": (v12/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": [
          (v9/*: any*/)
        ],
        "concreteType": "WalletType",
        "kind": "LinkedField",
        "name": "wallet",
        "plural": false,
        "selections": [
          {
            "alias": "baseFund",
            "args": (v10/*: any*/),
            "concreteType": "WalletFundsType",
            "kind": "LinkedField",
            "name": "fundsOf",
            "plural": false,
            "selections": (v14/*: any*/),
            "storageKey": null
          },
          {
            "alias": "wrappedFund",
            "args": (v13/*: any*/),
            "concreteType": "WalletFundsType",
            "kind": "LinkedField",
            "name": "fundsOf",
            "plural": false,
            "selections": (v14/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7ecdc8c7ab17563a26e63f2a3867f1e0",
    "id": null,
    "metadata": {},
    "name": "WrapTokenModalQuery",
    "operationKind": "query",
    "text": "query WrapTokenModalQuery(\n  $address: AddressScalar!\n  $baseSymbol: String!\n  $baseChain: ChainScalar!\n  $wrappedSymbol: String!\n  $wrappedChain: ChainScalar!\n) {\n  ...ConfirmWrapTransactionFragment_NvCQ7\n  ...WrapTransactionDetailsFragment_1fzPHO\n}\n\nfragment ConfirmWrapTransactionFragment_NvCQ7 on Query {\n  basePaymentAsset: paymentAsset(symbol: $baseSymbol, chain: $baseChain) {\n    usdPrice\n    id\n  }\n  wrappedPaymentAsset: paymentAsset(symbol: $wrappedSymbol, chain: $wrappedChain) {\n    usdPrice\n    id\n  }\n}\n\nfragment WrapTransactionDetailsFragment_1fzPHO on Query {\n  wallet(address: $address) {\n    baseFund: fundsOf(symbol: $baseSymbol, chain: $baseChain) {\n      balance: quantity\n      symbol\n      id\n    }\n    wrappedFund: fundsOf(symbol: $wrappedSymbol, chain: $wrappedChain) {\n      balance: quantity\n      symbol\n      id\n    }\n  }\n  basePaymentAsset: paymentAsset(symbol: $baseSymbol, chain: $baseChain) {\n    decimals\n    id\n  }\n  wrappedPaymentAsset: paymentAsset(symbol: $wrappedSymbol, chain: $wrappedChain) {\n    decimals\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "881f8221b8e00a79749b3885e55ecb4e";

export default node;
