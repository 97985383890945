import { useMemo } from "react"
import type { ChainIdentifier } from "./types"
import { useChains } from "./useChains"

export const useNativeSymbol = (chain: ChainIdentifier) => {
  const { getNativeCurrencySymbol } = useChains()

  return useMemo(
    () => getNativeCurrencySymbol(chain),
    [chain, getNativeCurrencySymbol],
  )
}
