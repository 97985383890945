import { classNames } from "@opensea/ui-kit"
import type { IconProps } from "@opensea/ui-kit/icons"
import { Error } from "@opensea/ui-kit/icons"
import React, { cloneElement, forwardRef } from "react"
import { Label } from "@/design-system/Label"

export type FormControlProps = Pick<
  JSX.IntrinsicElements["label"],
  "htmlFor"
> & {
  children: JSX.Element
  // Labels should always be specified for inputs for a11y - they are read by
  // screen readers when navigating to and focusing the input.
  // If the label should be visually hidden, set `hideLabel` to true.
  label: React.ReactNode
  captionRight?: React.ReactNode
  captionLeft?: React.ReactNode
  tip?: React.ReactNode
  tipAlign?: "bottom" | "right"
  error?: React.ReactNode
  warning?: React.ReactNode
  disabled?: boolean
  className?: string
  required?: boolean
  hideLabel?: boolean
  inline?: boolean
}

export const FormControl = forwardRef<HTMLDivElement, FormControlProps>(
  function FormControl(
    {
      children,
      label,
      htmlFor,
      captionLeft,
      captionRight,
      error,
      warning,
      disabled = false,
      required,
      hideLabel = false,
      tip,
      tipAlign = "bottom",
      inline = false,
      ...rest
    },
    ref,
  ) {
    const onlyChildrenProps = children.props

    const captionLeftElement = error || warning || captionLeft
    const showCaption = captionLeftElement || captionRight

    const $error = Boolean(error)
    const $warning = Boolean(warning)

    const content = cloneElement(children, {
      disabled: onlyChildrenProps.disabled || disabled,
      error: onlyChildrenProps.error || $error,
    })

    const labelContainerClassName =
      tipAlign === "bottom" ? "flex flex-col" : "flex justify-between"

    const htmlForWithFallback = htmlFor || onlyChildrenProps.id

    return (
      <div className="flex w-full max-w-full flex-col" {...rest} ref={ref}>
        <div
          className={classNames("flex flex-1", {
            "flex-col": !inline,
            "flex-row justify-between items-center": inline,
          })}
        >
          {label || tip ? (
            <div
              className={classNames(
                labelContainerClassName,
                !hideLabel && !inline && "mb-2",
              )}
            >
              {label ? (
                <Label
                  className={tip && tipAlign === "bottom" ? "mb-1" : undefined}
                  htmlFor={htmlForWithFallback}
                  required={required}
                  visuallyHidden={hideLabel}
                >
                  {label}
                </Label>
              ) : null}

              {tip ? (
                <span className="text-xs font-medium text-secondary">
                  {tip}
                </span>
              ) : null}
            </div>
          ) : null}

          {inline ? (
            <div className="flex flex-col items-center justify-center">
              {content}
            </div>
          ) : (
            content
          )}
        </div>

        {showCaption ? (
          <div className="mt-1 flex flex-col justify-center">
            <Caption
              $error={$error}
              $warning={$warning}
              aria-describedby={htmlForWithFallback}
              role={$error ? "status" : undefined}
            >
              {$error || $warning ? (
                <AlertIcon
                  $error={$error}
                  $warning={$warning ? !$error : undefined}
                  aria-label={$error ? "Error" : "Warning"}
                  className="mr-1"
                  size={14}
                />
              ) : null}
              <span>{captionLeftElement}</span>
            </Caption>
            {captionRight ? (
              <Caption className="ml-6 inline-block text-right">
                {captionRight}
              </Caption>
            ) : null}
          </div>
        ) : null}
      </div>
    )
  },
)

type CaptionsProps = React.HTMLAttributes<HTMLSpanElement> & {
  $error?: boolean
  $warning?: boolean
  children: React.ReactNode
  className?: string
}

function Caption({ children, $warning, $error, ...rest }: CaptionsProps) {
  return (
    <span
      {...rest}
      className={classNames(
        "flex text-sm text-secondary",
        {
          "text-warning": $warning,
          "text-error": $error,
        },
        rest.className,
      )}
    >
      {children}
    </span>
  )
}

function AlertIcon({
  $error,
  $warning,
  ...rest
}: IconProps & { $error?: boolean; $warning?: boolean }) {
  return (
    <Error
      {...rest}
      // Match caption text line height to ensure it's centered with the first line
      // of text. Important to avoid being overridden by .material-icons style.
      className={classNames(
        "self-start !leading-[inherit]",
        {
          "text-warning": $warning,
          "text-error": $error,
        },
        rest.className,
      )}
    />
  )
}
