import React, { useCallback, useState } from "react"
import { createContext } from "use-context-selector"
import type { ChainIdentifier } from "@/hooks/useChains/types"
import { noop } from "@/lib/helpers/noop"

export type AssetAction = "SEND" | "WRAP"

export type Asset = {
  chain: ChainIdentifier
  symbol: string
}

const DEFAULT_ASSET_DATA = {
  asset: null,
  action: null,
}

const DEFAULT_ASSET_CONTEXT: AssetContextType = {
  ...DEFAULT_ASSET_DATA,
  setAsset: noop,
  startAssetAction: noop,
  exitAssetAction: noop,
}

type AssetContextType = {
  asset: Asset | null
  action: AssetAction | null
  setAsset: (asset: Asset | null) => void
  startAssetAction: (asset: Asset, action: AssetAction) => void
  exitAssetAction: () => void
}

export const AssetContext = createContext<AssetContextType>(
  DEFAULT_ASSET_CONTEXT,
)

type AssetProviderProps = {
  children: React.ReactNode
}

export function AssetProvider({ children }: AssetProviderProps) {
  const [data, setData] = useState<{
    asset: Asset | null
    action: AssetAction | null
  }>(DEFAULT_ASSET_DATA)

  const setAsset = useCallback((asset: Asset | null) => {
    setData(prev => ({ ...prev, asset }))
  }, [])

  const startAssetAction = useCallback((asset: Asset, action: AssetAction) => {
    setData({ asset, action })
  }, [])

  const exitAssetAction = useCallback(() => {
    setData(prev => ({ ...prev, action: null }))
  }, [])

  return (
    <AssetContext.Provider
      value={{
        asset: data.asset,
        action: data.action,
        setAsset,
        startAssetAction,
        exitAssetAction,
      }}
    >
      {children}
    </AssetContext.Provider>
  )
}
