/**
 * @generated SignedSource<<ed5a444ac4dd8764b20a190486c8acde>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type useAddressFromENSResolverQuery$variables = {
  name: string;
};
export type useAddressFromENSResolverQuery$data = {
  readonly accountHelpers: {
    readonly address: string | null | undefined;
  };
};
export type useAddressFromENSResolverQuery = {
  response: useAddressFromENSResolverQuery$data;
  variables: useAddressFromENSResolverQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "name"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "AccountHelpersType",
    "kind": "LinkedField",
    "name": "accountHelpers",
    "plural": false,
    "selections": [
      {
        "alias": "address",
        "args": [
          {
            "kind": "Variable",
            "name": "name",
            "variableName": "name"
          }
        ],
        "kind": "ScalarField",
        "name": "resolveEnsName",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useAddressFromENSResolverQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useAddressFromENSResolverQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "8fa897a067e6cbbfe7845b3a520e0bbf",
    "id": null,
    "metadata": {},
    "name": "useAddressFromENSResolverQuery",
    "operationKind": "query",
    "text": "query useAddressFromENSResolverQuery(\n  $name: String!\n) {\n  accountHelpers {\n    address: resolveEnsName(name: $name)\n  }\n}\n"
  }
};
})();

(node as any).hash = "746c85cbd079e8aeec8e6c1df6a36cba";

export default node;
