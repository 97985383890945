import { useTranslate } from "@opensea/next-translate"
import { ArrowBack, ArrowForward } from "@opensea/ui-kit/icons"
import React, { useMemo } from "react"
import type { PreloadedQuery } from "react-relay"
import { graphql, useFragment, usePreloadedQuery } from "react-relay"
import { useAsyncFn } from "react-use"
import { TokenAmount } from "@/components/common/TokenAmount/TokenAmount.react"
import { Button } from "@/design-system/Button"
import { Skeleton } from "@/design-system/Skeleton.react"
import { useChains } from "@/hooks/useChains"
import type { ChainIdentifier } from "@/hooks/useChains/types"
import type { ConfirmWrapTransactionFragment$key } from "@/lib/graphql/__generated__/ConfirmWrapTransactionFragment.graphql"
import type { WrapTokenModalQuery } from "@/lib/graphql/__generated__/WrapTokenModalQuery.graphql"
import { isPolygon } from "@/lib/helpers/chainUtils"
import { bn, display, displayFiat } from "@/lib/helpers/numberUtils"
import type { WrapTransactionData } from "./WrapTransactionDetails.react"
import type { DirectionValueType } from "./WrapUnwrapRadioButton.react"
import { WRAP_TOKEN_QUERY } from "./WrapTokenModalQuery"

type ConfirmTransactionProps = {
  chain: ChainIdentifier
  direction: DirectionValueType
  queryRef: PreloadedQuery<WrapTokenModalQuery>
  quantity: string
  estimatedGas: string | null
  estimatingGas: boolean
  onConfirm: (props: WrapTransactionData) => Promise<void>
}

export function ConfirmWrapTransaction(props: ConfirmTransactionProps) {
  const { chain, direction, queryRef } = props

  const data = usePreloadedQuery(WRAP_TOKEN_QUERY, queryRef)

  const { basePaymentAsset, wrappedPaymentAsset } =
    useFragment<ConfirmWrapTransactionFragment$key>(
      graphql`
        fragment ConfirmWrapTransactionFragment on Query
        @argumentDefinitions(
          baseSymbol: { type: "String!" }
          baseChain: { type: "ChainScalar!" }
          wrappedSymbol: { type: "String!" }
          wrappedChain: { type: "ChainScalar!" }
        ) {
          basePaymentAsset: paymentAsset(
            symbol: $baseSymbol
            chain: $baseChain
          ) {
            usdPrice
          }
          wrappedPaymentAsset: paymentAsset(
            symbol: $wrappedSymbol
            chain: $wrappedChain
          ) {
            usdPrice
          }
        }
      `,
      data,
    )

  const paymentAsset =
    direction === "Wrap" ? basePaymentAsset : wrappedPaymentAsset

  const { getNativeCurrencySymbol, getWrappedCurrencySymbol } = useChains()

  const [baseSymbol, wrappedSymbol] = useMemo(() => {
    if (isPolygon(chain)) {
      return [getWrappedCurrencySymbol(chain), getWrappedCurrencySymbol(chain)]
    }

    return [getNativeCurrencySymbol(chain), getWrappedCurrencySymbol(chain)]
  }, [chain, getNativeCurrencySymbol, getWrappedCurrencySymbol])

  return (
    <ConfirmWrapTransactionBase
      {...props}
      baseSymbol={baseSymbol}
      usdPrice={paymentAsset.usdPrice}
      wrappedSymbol={wrappedSymbol}
    />
  )
}

export function ConfirmWrapTransactionBase({
  baseSymbol,
  direction,
  estimatedGas,
  estimatingGas,
  onConfirm,
  quantity,
  usdPrice,
  wrappedSymbol,
}: Omit<ConfirmTransactionProps, "chain" | "queryRef"> & {
  baseSymbol: string
  usdPrice: string
  wrappedSymbol: string
}) {
  const t = useTranslate("assets")
  const [{ loading }, confirmTransaction] = useAsyncFn(
    () =>
      onConfirm({
        quantity,
        direction,
      }),
    [onConfirm],
  )

  const renderAmount = () => {
    const usdValue = bn(usdPrice).times(quantity).toString()
    return (
      <div className="flex flex-col rounded-medium bg-component-gray-1 px-4 py-6">
        <div className="flex items-start justify-between">
          <div className="flex items-center space-x-3">
            <span className="font-semibold capitalize text-primary">
              {t("confirmWrapTransactionBase.token", "Token")}
            </span>
          </div>
          <div className="text-right text-primary">
            <div
              className="flex items-center space-x-2 font-semibold"
              data-testid="ConfirmWrapTransactionBase-amount"
            >
              <span data-testid="ConfirmWrapTransactionBase-native-amount">
                <TokenAmount amount={quantity} symbol={baseSymbol} />
              </span>
              {direction === "Unwrap" ? (
                <ArrowBack className="cursor-default" size={16} />
              ) : (
                <ArrowForward className="cursor-default" size={16} />
              )}
              <span data-testid="ConfirmWrapTransactionBase-wrapped-amount">
                <TokenAmount amount={quantity} symbol={wrappedSymbol} />
              </span>
            </div>
            <p
              className="text-secondary"
              data-testid="ConfirmWrapTransactionBase-amount-fiat"
            >
              {displayFiat(usdValue)}
            </p>
          </div>
        </div>
      </div>
    )
  }

  const renderGas = () => {
    if (!estimatedGas) {
      return null
    }

    return (
      <div className="flex flex-col rounded-medium bg-component-gray-1 px-4 py-6">
        <div className="flex items-start justify-between">
          <div className="flex items-center space-x-3">
            <span className="font-semibold capitalize text-primary">
              {t("confirmWrapTransactionBase.gas", "Gas")}
            </span>
          </div>
          <div className="flex flex-col items-end">
            {estimatingGas ? (
              <>
                <Skeleton className="my-0.5 h-5 w-24" />
                <Skeleton className="my-0.5 h-5 w-12" />
              </>
            ) : (
              <>
                <p
                  className="font-semibold text-primary"
                  data-testid="ConfirmWrapTransactionBase-gas"
                >
                  {display(estimatedGas)} {baseSymbol.toUpperCase()}
                </p>
                <p
                  className="text-secondary"
                  data-testid="ConfirmWrapTransactionBase-gas-fiat"
                >
                  {displayFiat(bn(estimatedGas).times(usdPrice).toString())}
                </p>
              </>
            )}
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="flex flex-1 flex-col justify-between">
      <div className="flex flex-col space-y-4">
        {renderAmount()}
        {renderGas()}
      </div>

      <Button
        className="w-full justify-center"
        disabled={loading || !estimatedGas || estimatingGas}
        isLoading={loading}
        onClick={() => confirmTransaction()}
      >
        {t("confirmWrapTransactionBase.confirm", "Confirm")}
      </Button>
    </div>
  )
}
