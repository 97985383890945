/**
 * @generated SignedSource<<12a570a1623bc1d29c61701422b0bd02>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SendTokenModalQuery$variables = {
  address: string;
  chain: | "ETHEREUM" | "MATIC" | "KLAYTN" | "BSC" | "SOLANA" | "ARBITRUM" | "ARBITRUM_NOVA" | "AVALANCHE" | "OPTIMISM" | "OPTIMISM_SEPOLIA" | "AMOY" | "BAOBAB" | "BSC_TESTNET" | "SOLDEV" | "ARBITRUM_SEPOLIA" | "AVALANCHE_FUJI" | "SEPOLIA" | "BASE" | "BASE_SEPOLIA" | "ZORA" | "ZORA_SEPOLIA" | "ZORA_TESTNET" | "BLAST_SEPOLIA" | "BLAST" | "GUNZILLA_TESTNET" | "SEI" | "SEI_TESTNET" | "%future added value";
  nativeSymbol: string;
  symbol: string;
};
export type SendTokenModalQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ConfirmTransactionFragment" | "TransactionDetailsFragment">;
};
export type SendTokenModalQuery = {
  response: SendTokenModalQuery$data;
  variables: SendTokenModalQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "address"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "chain"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "nativeSymbol"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "symbol"
},
v4 = {
  "kind": "Variable",
  "name": "address",
  "variableName": "address"
},
v5 = {
  "kind": "Variable",
  "name": "chain",
  "variableName": "chain"
},
v6 = {
  "kind": "Variable",
  "name": "symbol",
  "variableName": "symbol"
},
v7 = [
  (v5/*: any*/),
  (v6/*: any*/)
],
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "SendTokenModalQuery",
    "selections": [
      {
        "args": [
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "kind": "Variable",
            "name": "nativeSymbol",
            "variableName": "nativeSymbol"
          },
          (v6/*: any*/)
        ],
        "kind": "FragmentSpread",
        "name": "TransactionDetailsFragment"
      },
      {
        "args": [
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/)
        ],
        "kind": "FragmentSpread",
        "name": "ConfirmTransactionFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v3/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "SendTokenModalQuery",
    "selections": [
      {
        "alias": null,
        "args": [
          (v4/*: any*/)
        ],
        "concreteType": "WalletType",
        "kind": "LinkedField",
        "name": "wallet",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v7/*: any*/),
            "concreteType": "WalletFundsType",
            "kind": "LinkedField",
            "name": "fundsOf",
            "plural": false,
            "selections": [
              {
                "alias": "balance",
                "args": null,
                "kind": "ScalarField",
                "name": "quantity",
                "storageKey": null
              },
              {
                "alias": "assetImage",
                "args": null,
                "kind": "ScalarField",
                "name": "image",
                "storageKey": null
              },
              (v8/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": "nativeFunds",
            "args": [
              (v5/*: any*/),
              {
                "kind": "Variable",
                "name": "symbol",
                "variableName": "nativeSymbol"
              }
            ],
            "concreteType": "WalletFundsType",
            "kind": "LinkedField",
            "name": "fundsOf",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "quantity",
                "storageKey": null
              },
              (v8/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v7/*: any*/),
        "concreteType": "PaymentAssetType",
        "kind": "LinkedField",
        "name": "paymentAsset",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "decimals",
            "storageKey": null
          },
          (v8/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "usdPrice",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "6a22160e0954c89cff6bfd54028291a0",
    "id": null,
    "metadata": {},
    "name": "SendTokenModalQuery",
    "operationKind": "query",
    "text": "query SendTokenModalQuery(\n  $address: AddressScalar!\n  $chain: ChainScalar!\n  $symbol: String!\n  $nativeSymbol: String!\n) {\n  ...TransactionDetailsFragment_3Mr2uN\n  ...ConfirmTransactionFragment_1Ly3pG\n}\n\nfragment ConfirmTransactionFragment_1Ly3pG on Query {\n  paymentAsset(symbol: $symbol, chain: $chain) {\n    usdPrice\n    id\n  }\n  wallet(address: $address) {\n    fundsOf(symbol: $symbol, chain: $chain) {\n      assetImage: image\n      id\n    }\n  }\n}\n\nfragment TransactionDetailsFragment_3Mr2uN on Query {\n  wallet(address: $address) {\n    fundsOf(symbol: $symbol, chain: $chain) {\n      balance: quantity\n      assetImage: image\n      id\n    }\n    nativeFunds: fundsOf(symbol: $nativeSymbol, chain: $chain) {\n      quantity\n      id\n    }\n  }\n  paymentAsset(symbol: $symbol, chain: $chain) {\n    decimals\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "6b55f335f48c07a913320d1de4bb9bc1";

export default node;
